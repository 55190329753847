import React, { Component } from 'react';
import linkedinIcon from './assets/img/linkedinIcon.png'

import Link from 'react-router-dom/Link';
import Animation from './Animation';
import { Controller, Scene } from 'react-scrollmagic';import ReactPlayer from 'react-player'
var footerClass = "footer";



function LinkedinLink() {
return (
  <a href="https://www.linkedin.com/in/philipp-wienes-714382199/?originalSubdomain=de/" target="_blank" className="img-link">
    <img alt="linkedIn Icon" className="icon -small" src={linkedinIcon}/>
  </a>

)
}

function InstagramLink() {
  return (
      <a href="https://www.instagram.com/no1.philipp/" target="_blank" className="img-link">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      fill="white"
      y="0"
      enableBackground="new 0 0 551.034 551.034"
      version="1.1"
      className="icon -small instagramIcon"
      viewBox="0 0 551.034 551.034"
      xmlSpace="preserve"
    >


      <path
        fill="white"
        d="M386.878 0H164.156C73.64 0 0 73.64 0 164.156v222.722c0 90.516 73.64 164.156 164.156 164.156h222.722c90.516 0 164.156-73.64 164.156-164.156V164.156C551.033 73.64 477.393 0 386.878 0zM495.6 386.878c0 60.045-48.677 108.722-108.722 108.722H164.156c-60.045 0-108.722-48.677-108.722-108.722V164.156c0-60.046 48.677-108.722 108.722-108.722h222.722c60.045 0 108.722 48.676 108.722 108.722v222.722z"
      />

      <path
    fill="white"
        d="M275.517 133C196.933 133 133 196.933 133 275.516s63.933 142.517 142.517 142.517S418.034 354.1 418.034 275.516 354.101 133 275.517 133zm0 229.6c-48.095 0-87.083-38.988-87.083-87.083s38.989-87.083 87.083-87.083c48.095 0 87.083 38.988 87.083 87.083 0 48.094-38.989 87.083-87.083 87.083z"
      />
          <circle cx="418.31" cy="134.07" r="34.15" fill="white" />
    </svg>
      </a>
  );
}


class Footer extends React.Component {

    constructor(props) {
       super(props);
       this.state = {
         aboutmeIsOpen: false,
         scrollPosition: 0,
       };
        this.listenToScroll = this.listenToScroll.bind(this);
     }

    componentDidMount(){
      window.addEventListener('scroll', this.listenToScroll);

    }
  listenToScroll = () => {
    const screenHeight = document.documentElement.clientHeight;
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
    const scrolled = winScroll - screenHeight;

    this.setState({
      scrolled: scrolled,
      scrolledTotal: winScroll
    })

}



mouseEnterNein = (e) => {
  var randomY = Math.floor(Math.random() * (150 + 400)) -400;
  var randomX = Math.floor(Math.random() * (((2/3) * window.innerWidth) + 150)) -150;

  // var random = Math.floor(Math.random() * (+max - +min)) + +min;
    var theTarget = e.target;


      document.body.classList.add("nein");
      this.neinChild.innerHTML = "😝😝😝";
      this.neinChild.style.transform = 'translate3D(' + randomX + 'px,' + randomY + 'px, 0)';


}

mouseLeaveNein = (e) => {
    var theTarget = e.target;
  document.body.classList.remove("nein");
          this.neinChild.innerHTML = "Nöö 😔";
          this.neinChild.style.transform = 'translate3D(0,0,0)';
}



    render(){

          return (
      <React.Fragment>
              <footer id="footer" className={footerClass}
          ref={footerDiv => this.footerDiv = footerDiv}
          >


              <div className="footer-hero wrap ">
                  <div className="col-100_sm-100 grid-noGutter">
                          <div className="col-100_sm-100">
                      <h3 className="huge-h3">          {  this.props.context.state.translation.default.footer.h1}
                      </h3>
                      <form className="antworten">

  <div className="antwort -ja"
   onMouseEnter={this.mouseEnter}
   onMouseLeave={this.mouseLeave}>
  <Link
    to={{
      pathname: "/Kontakt",
      state: { trail: "fromFooter" }
    }}
 className="btn-primary"
  >
          {  this.props.context.state.translation.default.footer.yes} ✌️
      </Link>
      </div>
          <div className="antwort -nein"
             onMouseEnter={this.mouseEnterNein}
             ref={r=>this.nein = r}

             onMouseLeave={this.mouseLeaveNein}
             >

            <Link
              to={{
                pathname: "/error"
              }}
             ref={r=>this.neinChild = r}
           className="btn-primary"
            >
          {  this.props.context.state.translation.default.footer.no} 😔
            </Link>
                  </div>
                      </form>



                            {
                          //       <Link
                          //         to={{
                          //           pathname: '/Projektanfrage',
                          //           state: { trail: "fromHomeToProjektanfrage"},
                          //         }}
                          //         className="btn-primary btn"
                          //       >
                          // <i>
                          //
                          //                  <svg   stroke="rgba(255,255,255,0.6)" strokeWidth="0.5"  fill="rgba(255,255,255,0.6)"width="26px"  height="17px" id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.43 15.28"><title>iconmonstr-arrow-right-thin</title><path d="M12.26,19.14,11.32,18l7-5.79H0V10.77H18.31L11.31,5l.95-1.12,9.17,7.62Z" transform="translate(0 -3.86)"/></svg>
                          //                  <svg   stroke="rgba(255,255,255,0.6)" strokeWidth="0.5"  fill="rgba(255,255,255,0.6)"width="26px"  height="17px" id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.43 15.28"><title>iconmonstr-arrow-right-thin</title><path d="M12.26,19.14,11.32,18l7-5.79H0V10.77H18.31L11.31,5l.95-1.12,9.17,7.62Z" transform="translate(0 -3.86)"/></svg>
                          //   </i>
                          //
                          //   <span>  Kontakt aufnehmen</span>
                          //   </Link>
                            }
                  </div>
              </div>
      </div>
                <div className="wrap grid footerNav">
                    <div className="col-80_sm-100 ">
                    <div className="flex ">
                        <div
                        className="link-wrap"
                        >
    <Link
        to={{
          pathname: "/",
          state: { trail: "fromFooter" }
        }}
        className="text-link"
      >
  Home
    </Link>
    <Link
          to={{
            pathname: "/Works",
            state: { trail: "fromFooter" }
          }}
          className="text-link"
        >
    {  this.props.context.state.translation.default.menuModal.works}
      </Link>
      <Link
          to={{
            pathname: "/Leistungen",
            state: { trail: "fromFooter" }
          }}
          className="text-link"
        >
    {  this.props.context.state.translation.default.menuModal.services}
      </Link>

    <Link
        to={{
          pathname: "/About",
          state: { trail: "fromFooter" }
        }}
        className="text-link"
      >
{  this.props.context.state.translation.default.menuModal.about}
    </Link>


                                                              <Link
                                                                                to={{
                                                                                  pathname: "/Kontakt",
                                                                                  state: { trail: "fromFooter" }
                                                                                }}
                                                                                className="text-link"
                                                                              >
                                                                          {  this.props.context.state.translation.default.footer.contact}
                                                                            </Link>


                          <Link
                              to={{
                                pathname: "/Impressum",
                                state: { trail: "fromFooter" }
                              }}
                              className="text-link"
                            >
                        {  this.props.context.state.translation.default.footer.impressum}
                          </Link>

                                                    <Link
                                                        to={{
                                                          pathname: "/Datenschutz",
                                                          state: { trail: "fromFooter" }
                                                        }}
                                                        className="text-link"
                                                      >
                                                  {  this.props.context.state.translation.default.footer.data}
                                                    </Link>
                      </div>

                    </div>


                    </div>

                          <div className="footer-copyright col-20_sm-100 ">

                      <div className="socialmedia-wrap">
                                    <LinkedinLink>
                                    </LinkedinLink>
                                    <InstagramLink>
                                    </InstagramLink>
                      </div>
                    </div>
                </div>

                <div className="footer-img">

                </div>


          </footer>

          </React.Fragment>
  );
}
}

export default Footer
