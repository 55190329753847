import React from 'react';
import Page from './Page';


class Error404 extends React.Component {
  componentDidMount() {
        window.scrollTo(0, 0);
      }
      render(){

  return (
    <Page pageName="error" pageHero="404" >

    </Page>
  );
      }
}
export default Error404;
