import React from "react";
import Page from "./Page";

import ProjectNav from "./ProjectNav";
import { Tween, Timeline } from "react-gsap";
import { Controller, Scene } from "react-scrollmagic";
import imgProjectPickshare from "./assets/img/pickshare-mockup.png";
import pfotenMockup from "./assets/img/autarq.png";
import pfotenMockupMobile from "./assets/img/autarq-mobile.png";

import LazyLoad from "react-lazyload";
import Hyphenated from "react-hyphen";
import de from "hyphenated-de";
import autarqIntroMp4 from "./assets/img/autarq.webm";
import autarqIntroWebm from "./assets/img/autarq.mp4";

import { MyContext } from "./Context";

import ReactPlayer from "react-player";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/opacity.css";
class Autarq extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <MyContext.Consumer>
        {(context) => (
          <Page
            pageName="Autarq"
            pageType="Project"
            pageHero="Autarq"
            pageStyle="Project"
          >
            <section
              className="projectContent"
              style={{ paddingBottom: "20vmin" }}
            >
              <div className="lazy-load-image-background-wrap -displayMobile">
                <LazyLoadImage
                  effect="opacity"
                  className="-displayMobile"
                  src={pfotenMockupMobile}
                />
              </div>
              <Controller>
                <Scene duration="250%" triggerHook={0.6}>
                  <Timeline
                    wrapper={<div className="parallax -displayDesktop" />}
                  >
                    <Tween
                      position="0"
                      from={{
                        yPercent: -30,
                      }}
                      to={{
                        yPercent: 20,
                      }}
                    >
                      <figure className="lazy-load-image-background-wrap -displayDesktop">
                        <LazyLoadImage effect="opacity" src={pfotenMockup} />
                      </figure>
                    </Tween>
                  </Timeline>
                </Scene>
              </Controller>

              <section>
                <div className="wrap">
                  <div className="project-intro grid">
                    <div className=" col-28_sm-100 grid-top project-infos">
                      <div className="col-100_sm-50">
                        <div className="client-wrap ">
                          <h5>Client</h5>
                          <p className="">Autarq</p>
                        </div>
                      </div>

                      <div className="scope-wrap col-100_sm-50">
                        <h5>Scope</h5>
                        <p className="">Creative Coding</p>
                        <p className="">GSAP</p>
                        <p className="">Vue.js + Nuxt </p>
                      </div>
                    </div>

                    <div className=" col-65_sm-100">
                      <p className="p-big">
                        <Hyphenated language={de}>
                          {context.state.translation.default.autarq.h1}
                        </Hyphenated>
                      </p>
                      <a
                        class="btn-primary btn"
                        target="_blank"
                        href="https://autarq.com"
                      >
                        See it in action
                      </a>
                    </div>
                  </div>
                </div>

                <div className="wrap">
                  <figure class="overlayed">
                    {" "}
                    <LazyLoad height={200} offset={100}>
                      <ReactPlayer
                        controls={false}
                        muted={true}
                        ref={this.ref}
                        playing={true}
                        loop={true}
                        width="100%"
                        height="100%"
                        url={[autarqIntroMp4, autarqIntroWebm]}
                      />
                    </LazyLoad>
                  </figure>
                </div>
              </section>
            </section>

            {
              // <ProjectFooter>
              // </ProjectFooter>
            }
            <ProjectNav context={context} pageName="Autarq"></ProjectNav>
          </Page>
        )}
      </MyContext.Consumer>
    );
  }
}
export default Autarq;
