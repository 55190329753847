
import React from "react";
import Page from "./Page";
import Footer from "./Footer";
import Link from 'react-router-dom/Link';
import ReactPlayer from 'react-player';
import Slider from "react-slick";
import { Controller, Scene } from "react-scrollmagic";
import { Tween, Timeline } from 'react-gsap';
import video from "./assets/img/kontakt.webm";
import illuMe from "./assets/img/miniportrait.gif";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import MediaQuery from 'react-responsive'
const transition = {duration: .3, ease: [0.43, 0.13, 0.23, 0.96]};
import {motion} from 'framer-motion';
import { MyContext } from './Context';


class Kontakt extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  }
  componentDidMount() {
        window.scrollTo(0, 0);
    this.setState({
      isCopied: false
    });
    document.getElementById("menuInfos").className =
        "menuInfos pageload";
  }

  notification(isCopied){
    if(isCopied){
        setTimeout(function(){
             this.setState({isCopied:false});
        }.bind(this),2000);

        return(
          <div className="notification">
{  context.state.translation.default.menuModal.successMessage}
          </div>
        )
    }

  }

  render() {
    return (
      <MyContext.Consumer>
        {(context) => (

      <Page
        pageName="Kontakt"
        loaderText="Kontakt"
        pageType="Kontakt"
        pageStyle="bright"
      >
      <MediaQuery maxDeviceWidth={768}>
            <video  autoPlay  playsinline muted width='100%' height='100%' className="-displayMobile" src={video}>
            </video>
        </MediaQuery>


           <MediaQuery minDeviceWidth={769}>
           <div   className="video-wrap">

                 <motion.video initial={{opacity:0}} animate={{opacity:1, transition:{ duration:1}}}  autoPlay  playsinline muted width='100%' height='100%' className="-displayDesktop" src={video}>

                 </motion.video>
           </div>
           </MediaQuery>



<section>
    <div className="wrap Kontakt-textBlock">

        <div className="grid -spaceBetween">
        <div className="col-50_sm-100">
              <h2 >{  context.state.translation.default.contact.h2}
      </h2>
      <p>{  context.state.translation.default.contact.p}

</p>

          <Link
            to={{
              pathname: "/Projektanfrage",
              state: { trail: "fromLeistungenToProjektanfrage" }
            }}
            className="btn-primary btn"
          >

            <span> {  context.state.translation.default.menuModal.formular}
 </span>
            <span className="btn-bg"></span>
                  <i>
                    <svg
                      stroke="rgba(255,255,255,0.6)"
                      strokeWidth="0.5"
                      fill="rgba(255,255,255,0.6)"
                      width="26px"
                      height="17px"
                      id="Ebene_1"
                      data-name="Ebene 1"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 21.43 15.28"
                    >
                      <title>iconmonstr-arrow-right-thin</title>
                      <path
                        d="M12.26,19.14,11.32,18l7-5.79H0V10.77H18.31L11.31,5l.95-1.12,9.17,7.62Z"
                        transform="translate(0 -3.86)"
                      />
                    </svg>
                    <svg
                      stroke="rgba(255,255,255,0.6)"
                      strokeWidth="0.5"
                      fill="rgba(255,255,255,0.6)"
                      width="26px"
                      height="17px"
                      id="Ebene_1"
                      data-name="Ebene 1"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 21.43 15.28"
                    >
                      <title>iconmonstr-arrow-right-thin</title>
                      <path
                        d="M12.26,19.14,11.32,18l7-5.79H0V10.77H18.31L11.31,5l.95-1.12,9.17,7.62Z"
                        transform="translate(0 -3.86)"
                      />
                    </svg>
                  </i>
          </Link>
        </div>

        <div className="col-50_sm-100 kontaktdaten-wrap">

<div>

        <h5>{  context.state.translation.default.contact.contactinfo}
</h5>
        <div

          className="kontaktInfos-link dropdown"
        ><button>  <svg
            xmlnsXlink="http://www.w3.org/1999/xlink"
            version="1.1"
            x="0px"
            y="0px"
            width="100%"
            height="100%"
            fill="rgb(120, 132, 157)"
            viewBox="0 0 35 35"
            enableBackground="new 0 0 35 35"
            xmlSpace="preserve"
          >
            <path d="M33.5 24V11c0-2.757-2.243-5-5-5h-22c-2.757 0-5 2.243-5 5v13c0 2.757 2.243 5 5 5h22c2.757 0 5-2.243 5-5zm-5-16c.462 0 .894.114 1.285.3L18.206 19.878a1.023 1.023 0 0 1-1.413 0L5.215 8.301C5.605 8.113 6.038 8 6.5 8h22zm-25 16V11c0-.462.114-.894.3-1.285l11.578 11.578c.567.566 1.32.878 2.122.878s1.555-.312 2.121-.878L31.2 9.715c.188.39.301.823.301 1.285v13c0 1.654-1.346 3-3 3h-22c-1.654 0-3-1.346-3-3z" />
          </svg>
            Philipp.Wienes@einfach.es</button>

          <div className="dropdown-menu">
    <a onClick={() => {
      navigator.clipboard.writeText("  Philipp.Wienes@einfach.es");
      this.setState ({ isCopied: true})
      }
      }

      className="dropdown-item clipboard"><svg width="18" height="18" xmlns="http://www.w3.org/2000/svg"><g fill="#F5C15D" fillRule="nonzero"><path d="M15.08 5.794H8.304a2.51 2.51 0 00-2.51 2.51v6.776a2.51 2.51 0 002.51 2.51h6.776a2.51 2.51 0 002.51-2.51V8.304a2.51 2.51 0 00-2.51-2.51zm-6.776 1.5h6.776c.558 0 1.01.452 1.01 1.01v6.776a1.01 1.01 0 01-1.01 1.01H8.304a1.01 1.01 0 01-1.01-1.01V8.304c0-.558.452-1.01 1.01-1.01z"></path><path d="M9.712.25c1.235 0 2.246.96 2.329 2.174l.005.16v.792a.75.75 0 01-1.493.102l-.007-.102v-.792a.834.834 0 00-.73-.828l-.104-.006H2.584a.834.834 0 00-.828.73l-.006.104v7.128c0 .425.318.776.73.828l.104.006h.792a.75.75 0 01.102 1.493l-.102.007h-.792A2.334 2.334 0 01.255 9.872l-.005-.16V2.584c0-1.235.96-2.246 2.174-2.329l.16-.005h7.128z"></path></g></svg>{  context.state.translation.default.menuModal.copy}</a>
    <div className="dropdown-divider"></div>
    <a className="dropdown-item" href="mailto:  Philipp.Wienes@einfach.es"><svg width="18" height="15" xmlns="http://www.w3.org/2000/svg"><g fill="#F5C15D" fillRule="nonzero"><path d="M15.256.25H2.584A2.339 2.339 0 00.25 2.584v9.504a2.339 2.339 0 002.334 2.334h12.672a2.339 2.339 0 002.334-2.334V2.584A2.339 2.339 0 0015.256.25zM2.584 1.75h12.672c.457 0 .834.377.834.834v9.504a.839.839 0 01-.834.834H2.584a.839.839 0 01-.834-.834V2.584c0-.457.377-.834.834-.834z"></path><path d="M16.41 1.97a.75.75 0 01.946 1.158l-.086.07-7.92 5.544a.75.75 0 01-.76.06l-.1-.06L.57 3.198a.75.75 0 01.765-1.285l.095.057 7.49 5.242 7.49-5.242z"></path></g></svg>{  context.state.translation.default.menuModal.mailtool}</a>
</div>
        </div>

        

      </div>
      </div>
  </div>
    </div>

</section>



    {this.notification(this.state.isCopied)}


      </Page>
    )}
    </MyContext.Consumer>
    );
  }
}

export default Kontakt;


{
  // import React from "react";
  // import Page from "./Page";
  // import Footer from "./Footer";
  // import Link from 'react-router-dom/Link';
  // import illuDesign from './assets/img/illuDesign.svg';
  // import illuKonzeption from './assets/img/illuKonzeption.svg';
  // import phil from "./assets/img/banana2.jpg";
  // import philAtWork from "./assets/img/philAtWork.png";
  // import illuEntwicklung from './assets/img/illuEntwicklung.svg';
  //
  // import { Controller, Scene } from "react-scrollmagic";
  //
  //
  // class Kontakt extends React.Component {
  //
  //   constructor(props) {
  //     super(props);
  //     this.state = {
  //     };
  //   }
  //   componentDidMount() {
  //   }
  //
  //
  //   render() {
  //     return (
  //       <Page
  //         pageName="Kontakt"
  //                 loaderText="Das bin ich"
  //         pageType="Kontakt"
  //         pageHero="Digitale Produkte, die Spaß machen."
  //         pageStyle="bright"
  //       >
  //         <img className="bigImage-portrait"
  //           alt="Foto von Philipp"
  //           title="Foto eines 30 Jährigen (jupp) jungen Mannes."
  //           src={phil}
  //         />
  //     <div className="wrap grid">
  //
  //         <div className="col-40_sm-100">
  //         <h3>Ich biete die ideale Kombination aus Kreativität und technischem Verständnis.</h3>
  //         </div>
  //
  //
  //
  //
  //         <div  data-push-left="off_sm-0 off-5" className="col-55_sm-100">
  // <p>
  // Ich bin Philipp Wienes, studierter, freiberuflicher Gestalter, Entwickler und Medienkünstler aus Bremen.
  // Seit über 8 Jahren entwerfe ich hochwertige Benutzeroberflächen und Brandings für Unternehmen aus allen Bereichen.
  // Mein Hauptziel ist es, schöne, intuitiv bedienbare, digitale Erlebnisse zu gestalten, die im Kopf bleiben.
  // Ich liebe einfache Formen, neue Technologien und klare Farbwelten.
  // </p>
  // </div>
  //
  //
  //   <div className="col-100_sm-100">
  //
  // <img className="img"     title="Foto eines fleißigen Gestalters bei der Arbeit." style={{marginTop: '4em', marginBottom: '2em'}} src={philAtWork}/>
  // </div>
  //
  //
  //       <div style={{marginTop: '2em'}} data-push-left="off-15 off_sm-0" className="col-70_sm-100">
  // <p>
  //     Am liebsten arbeite ich mit innovativen finetech Firmen an digitalen Produkten der Zukunft.
  //     Mein Herz schlägt aber auch für all die Künstler aus den unterschiedlichsten Bereichen da draußen.
  //     Wenn du dich angesprochen fühlst, und Hilfe bei deiner digitalen Präsenz brauchst, melde dich sehr gerne bei mir.
  //     Und keine Angst, über Geld redet man. 😊👆 Schreibt mir einfach, ein Gespräch kostet nichts und man weiß nie wohin es führen könnte. 😊
  //
  // </p>
  //
  // </div>
  //     <div  className="col-100_sm-100">
  //
  // <blockquote>"Zukunftsforschung ist die Kunst, <br/>sich zu kratzen, bevor es juckt. "</blockquote>
  //
  // </div>
  //         <div  data-push-left="off-15 off_sm-0" className="col-70_sm-100">
  // <p >
  // Neben meiner Arbeit als Freiberufler, entwickle ich medienkünstlerische Arbeite, die sich im Zwischenraum von Kunst, Design und Technologie bewegen.
  // In ihnen versuche ich auf spielerische Art den
  // Zeitgeist der technologisierten Gesellschaft widerzugeben.
  //
  // Für meine Arbeiten bin ich immer auf der Suche nach
  //   geeigneten Ausstellungsmöglichkeiten.
  //  </p>
  //
  // </div>
  //
  // </div>
  //
  //       </Page>
  //     );
  //   }
  // }
  //
  // export default Kontakt;
}
