import React from "react";
import ScrollToTop from "./ScrollToTop";

import { render } from "react-dom";
import { Route, Switch, BrowserRouter, useLocation } from "react-router-dom";

import { motion, AnimatePresence } from "framer-motion";
import { createBrowserHistory } from "history";

import { CSSTransition, TransitionGroup } from "react-transition-group";

import "./assets/style.css";
import Home from "./Home";
import Leistungen from "./Leistungen";
import Works from "./Works";
import Error404 from "./Error404";
import Pickshare from "./Pickshare";
import Projektanfrage from "./Projektanfrage";
import Foodfriends from "./Foodfriends";
import Hyperactive from "./Hyperactive";
import Autarq from "./Autarq";
import Datenschutz from "./Datenschutz";
import Anymotion from "./Anymotion";
import Appdesign from "./Appdesign";
import Webdev from "./Webdev";
import Branding from "./Branding";
import Pfotenphysio from "./Pfotenphysio";
const verifyAuthentication = false;
import HammerUndPartner from "./HammerUndPartner";
import Caera from "./Caera";
import Kontakt from "./Kontakt";
import Impressum from "./Impressum";
import About from "./About";
var oldWidth = 0;
import { LanguageHandler } from "./Context";
export const history = createBrowserHistory({
  basename: process.env.PUBLIC_URL,
});

const appHeight = () => {
  const doc = document.documentElement;
  if (oldWidth == window.innerWidth) {
  } else {
    doc.style.setProperty("--app-height", `${window.innerHeight}px`);
  }
  oldWidth = window.innerWidth;
};
window.addEventListener("resize", appHeight);
appHeight();

const App = () => (
  <LanguageHandler>
    <BrowserRouter basename="">
      <Route
        render={({ location }) => (
          <div className="white">
            <React.Fragment>
              <AnimatePresence exitBeforeEnter>
                <Switch location={location} key={location.key}>
                  <Route
                    exact
                    path="/Projektanfrage"
                    component={Projektanfrage}
                  />
                  <Route
                    exact
                    path="/"
                    key={100 + Math.random() * (100 - 1)}
                    component={Home}
                  />
                  <Route
                    exact
                    path="/grow"
                    key={100 + Math.random() * (100 - 1)}
                    component={Home}
                  />
                  <Route path="/Works" component={Works} />{" "}
                  <Route path="/Autarq" component={Autarq} />
                  <Route path="/About" component={About} />
                  <Route path="/Appdesign" component={Appdesign} />
                  <Route path="/Kontakt" component={Kontakt} />
                  <Route path="/Leistungen" component={Leistungen} />
                  <Route path="/Impressum" component={Impressum} />
                  <Route path="/Pfotenphysio" component={Pfotenphysio} />
                  <Route path="/Pickshare" component={Pickshare} />{" "}
                  <Route
                    path="/HammerUndPartner"
                    component={HammerUndPartner}
                  />
                  <Route path="/Datenschutz" component={Datenschutz} />
                  <Route path="/Anymotion" component={Anymotion} />
                  <Route path="/Webdev" component={Webdev} />{" "}
                  <Route path="/Branding" component={Branding} />
                  <Route path="/Hyperactive" component={Hyperactive} />
                  <Route path="/Foodfriends" component={Foodfriends} />
                  <Route path="/Caera" component={Caera} />
                  <Route component={Error404} />
                </Switch>
              </AnimatePresence>
            </React.Fragment>
          </div>
        )}
      />
    </BrowserRouter>
  </LanguageHandler>
);

render(<App />, document.getElementById("top"));
