import React from "react";
import Page from "./Page";

import ProjectNav from "./ProjectNav";
import { Tween, Timeline } from "react-gsap";
import { Controller, Scene } from "react-scrollmagic";
import imgProjectPickshare from "./assets/img/pickshare-mockup.png";
import imgProjectPickshareAbbinder from "./assets/img/pickshare-abbinder.jpg";
import anymotionScreens from "./assets/img/projekte/anymotion/anymotionScreens.png";
import anymotionScreensMobile from "./assets/img/projekte/anymotion/anymotionScreens-mobile.png";

import anymotionScreensPlaceholder from "./assets/img/projekte/anymotion/anymotionScreensPlaceholder.png";

import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/opacity.css";
<LazyLoadImage effect="opacity" src={anymotionScreens} />;

import anymotionTeaser from "./assets/img/any.mp4";
import anymotionMenu from "./assets/img/anymenu.mp4";
import ps1 from "./assets/img/pickshare01.png";
import ps2 from "./assets/img/pickshare02.png";
import ps3 from "./assets/img/pickshare03.png";
import ReactPlayer from "react-player";
class AnymotionGROUP extends React.Component {
  render() {
    return (
      <Page
        pageName="AnymotionGROUP"
        pageType="Project"
        loaderText="🎨 AnymotionGROUP"
        pageHero="AnymotionGROUP"
        pageStyle="Project"
      >
        <div className="projectContent" style={{ paddingBottom: "20vmin" }}>
          <div className="lazy-load-image-background-wrap -displayMobile">
            <LazyLoadImage
              effect="opacity"
              className="-displayMobile"
              src={anymotionScreensMobile}
            />
          </div>
          <Controller>
            <Scene duration="250%" triggerHook={0.6}>
              <Timeline wrapper={<div className="parallax -displayDesktop" />}>
                <Tween
                  position="0"
                  from={{
                    yPercent: -30,
                  }}
                  to={{
                    yPercent: 20,
                  }}
                >
                  <img src={anymotionScreens} />
                </Tween>
              </Timeline>
            </Scene>
          </Controller>

          <section>
            <div className="wrap">
              <div className="project-intro grid">
                <div className=" col-28_sm-100 grid-top project-infos">
                  <div className="col-100_sm-50">
                    <div className="client-wrap ">
                      <h5>Client</h5>
                      <p className="">Caera, Beurer</p>
                    </div>
                    <div className="jahr-wrap">
                      <h5>Jahr</h5>
                      <p className="">2021</p>
                    </div>
                  </div>

                  <div className="scope-wrap col-100_sm-50">
                    <h5>Scope</h5>
                    <p className="">UX/UI Design</p>
                    <p className="">Rapid Prototyping</p>
                  </div>
                </div>

                <div className=" col-65_sm-100">
                  {" "}
                  <h2>
                    Eine Webseite, die drei Agenturen unter einem Dach bündelt.
                  </h2>
                  <p>
                    Ziel war es eine moderne Website zu entwerfen für den den
                    Zusammenschluss von drei Agenturen unter einem Dach. Dabei
                    wurde großen Wert auf eine minimalistische, ästhetisch
                    hochwertig anmutende Gestaltung gelegt.
                  </p>
                </div>
              </div>
            </div>
          </section>

          <Controller>
            <Scene
              duration={600}
              triggerElement="#mainVid-wrap"
              triggerHook={0.3}
              classToggle="-active"
            >
              {(progress, event) => {
                if (progress > 0) {
                  var isPlaying = true;
                } else {
                  var isPlaying = false;
                }
                if (progress >= 1) {
                  var isPlaying = false;
                }
                return (
                  <figure>
                    <ReactPlayer
                      controls={false}
                      muted={true}
                      ref={this.ref}
                      id="mainVid-wrap"
                      playing={isPlaying}
                      loop={true}
                      width="100%"
                      height="100%"
                      url={anymotionTeaser}
                    />
                  </figure>
                );
              }}
            </Scene>
          </Controller>
        </div>

        {
          // <ProjectFooter>
          // </ProjectFooter>
        }
        <ProjectNav pageName="AnymotionGROUP"></ProjectNav>
      </Page>
    );
  }
}
export default AnymotionGROUP;
