import React from 'react';
import Page from './Page';
import Footer from './Footer';
import { MyContext } from './Context';


class Datenschutz extends React.Component {
  componentDidMount() {
  window.scrollTo(0, 0);
  }
render() {

  return (

      <MyContext.Consumer>
        {(context) => (
    <Page pageName="Datenschutz" pageHero="Datenschutz" pageStyle="">
  <section className="Datenschutz">
<div className="grid wrap">
      <div className="col-20">
      </div>
      <div className="col-70">
    <h3>  {  context.state.translation.default.datapolicy.h3}</h3>
    <h4> {  context.state.translation.default.datapolicy.h4}</h4>
<p>{  context.state.translation.default.datapolicy.p1}
    </p>
    <h3>   {  context.state.translation.default.datapolicy.h32}  </h3>
    <h4>   {  context.state.translation.default.datapolicy.h42}</h4>
    <p>  {  context.state.translation.default.datapolicy.p2}
      </p>
      <p>
      <strong>Philipp Wienes</strong><br/>
      Bismarckstraße 157<br/>
      28203 Bremen<br/>
      </p>
      <p>
      Telefon: 01578 8495951 <br/>
      E-Mail: hallo@einfach.es <br/>
      </p>
<p>
{  context.state.translation.default.datapolicy.p3}
</p>
      <h3>  {  context.state.translation.default.datapolicy.h33} </h3>
    <h4>  {  context.state.translation.default.datapolicy.h43}</h4><p>
    {  context.state.translation.default.datapolicy.p4}
</p>
    <h4>  {  context.state.translation.default.datapolicy.h44}</h4><p>
    {  context.state.translation.default.datapolicy.p5}
</p>
<h4>{  context.state.translation.default.datapolicy.h45}</h4>
<p>{  context.state.translation.default.datapolicy.p6}</p>
<p>
Diese Website nutzt den Webanalysedienst Matomo (vormalig Piwik), um die Nutzung unserer Website analysieren und regelmäßig verbessern zu können. Über die gewonnenen Statistiken können wir unser Angebot verbessern und für Sie als Nutzer interessanter ausgestalten. Rechtsgrundlage für die Nutzung von Matomo ist Art. 6 Abs. 1 S. 1 lit. f DS-GVO.
Für diese Auswertung werden keine Cookies auf Ihrem Computer gespeichert. Die Verhinderung des Einsatzes von Matomo ist möglich, indem Sie hier den Haken entfernen und so das Opt-out-Plug-in aktivieren
Diese Website verwendet Matomo mit der Einstellung „Anonymize IP“. Dadurch werden IP-Adressen gekürzt weiterverarbeitet, eine direkte Personenbeziehbarkeit kann damit ausgeschlossen werden. Die mittels Matomo von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen von uns erhobenen Daten zusammengeführt.
Das Programm Matomo ist ein Open-Source-Projekt. Informationen des Drittanbieters zum Datenschutz nach der DS-GVO erhalten Sie beispielsweise unter https://matomo.org/gdpr-roadmap/.
</p>    <iframe
            style={{height: '200px', width: '600px'}}
            src="https://analytics.haenschenklein.wtf/index.php?module=CoreAdminHome&action=optOut&language=de&backgroundColor=&fontColor=&fontSize=&fontFamily="
            ></iframe>
      </div>
    </div>

    </section>

    </Page>
  )}
</MyContext.Consumer>
  );
}
}
export default Datenschutz;
