
import React from "react";
import illuKonzeption from "./assets/img/illuKonzeption2.webm";
import marlon from './assets/img/marlon.png';

import caeralogo  from "./assets/img/caera.svg";
import { Controller, Scene } from "react-scrollmagic";
import Link from "react-router-dom/Link";
import Hyphenated from 'react-hyphen';
import de from 'hyphenated-de';
class Testemonials extends React.Component {

render(){
  return (
    <React.Fragment>

      <Controller>
        <Scene classToggle={"-active"} triggerHook={0.8}>
          <section className="testemonials-section testemonial-slides">
            <div className="wrap-wide grid-noGutter">


        <div className="testemonial-caera testemonial col-50_sm-100 ">
          <div className=" testemonial-slide">
            <p className="p-mini testemonial-text">
              <em>„</em><Hyphenated language={de}>{  this.props.context.state.translation.default.testemonials.marlon }</Hyphenated >
              <em>“</em>{" "}
            </p>
            <div >
              <div  className="col img-wrap">
                <img alt="" src={marlon} />
              </div>
              <div className="col">
                <p className="f-blue p-tiny uppercase">Marlon Besuch</p>
                <p className="p-tiny">CEO Microsynetics</p>
              </div>
            </div>
          </div>
        </div>
              <div className="testemonial-app testemonial col-50_sm-100 ">
                <div className=" testemonial-slide">
                  <p className="p-mini testemonial-text">
                    <em>„</em><Hyphenated language={de}>{  this.props.context.state.translation.default.testemonials.kevin }</Hyphenated>
                    <em>“</em>{" "}
                  </p>
                  <div className="flexwrap">
                    <div className="col">
                      <img alt="" src="https://app-pilots.de/img/kevin.png" />
                    </div>
                    <div className="col">
                      <p className="f-blue p-tiny uppercase">Kevin Beyer</p>
                      <p className="p-tiny">CEO App-pilots</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Scene>
      </Controller>
    {
    //   <section className="clients-section">
    //     <div className="wrap-300vw grid-noGutter">
    //             <div className="col-5 ">
    //               <div className="logo-wrap">
    //                 <img alt="" src={caeralogo} />
    //               </div>
    //             </div>
    //       <div className="col-5 ">
    //         <div className="logo-wrap">
    //           <img alt="" src="https://app-pilots.de/img/logo-inverse.png" />
    //         </div>
    //       </div>
    //
    //       <div className="col-6 ">
    //         <div className="logo-wrap">
    //           <img alt="" src="http://foodfriends.net/img/brand.png" />
    //         </div>
    //       </div>
    // <div className="col-5 ">
    //   <div className="logo-wrap">
    //     <img
    //       alt=""
    //       src="https://www.rhenus.com/fileadmin/templates/rhenus/img/rhenus_logo.png"
    //     />
    //   </div>
    // </div>
    // <div className="col-5 ">
    //   <div className="logo-wrap">
    //     <img
    //       alt=""
    //       src="https://www.reckstangen.de/images/uploads/TOLYMP-Logo.png"
    //     />
    //   </div>
    // </div>
    //       <div className="col-5 ">
    //         <div className="logo-wrap">
    //           <img
    //             alt=""
    //             className="client-logo"
    //             src="https://s.diviac.com/flatpages/img/logo.png"
    //           />
    //         </div>
    //       </div>
    //
    //       <div className="col-5">
    //         <div className="logo-wrap">
    //           <img
    //             alt=""
    //             src="https://kronen.markenstorefinder.de/img/logo.png"
    //           />
    //         </div>
    //       </div>
    //
    //       <div className="col-5">
    //         <div className="logo-wrap">
    //           <img
    //             alt=""
    //             src="https://www.comes.de/frontend/img/comes_logo_rgb.svg"
    //           />
    //         </div>
    //       </div>
    //
    //       <div className="col-5">
    //         <div className="logo-wrap">
    //           <img
    //             alt=""
    //             src="https://image.jimcdn.com/app/cms/image/transf/dimension=443x10000:format=png/path/s1b8e7b5a87029f33/image/i10e97990e3294336/version/1550528626/image.png"
    //           />
    //         </div>
    //       </div>
    //
    //       <div className="col-5">
    //         <div className="logo-wrap">
    //           <img
    //             alt=""
    //             src="https://images.ctfassets.net/vrkkgjbn4fsk/5PA9DARbcQ4gGoiksoMUkO/59a88b9368e9c1a4689444bed555f1ac/Yellow_Pages_Color.png"
    //           />
    //         </div>
    //       </div>
    //       <div className="col-5">
    //         <div className="logo-wrap">
    //           <img
    //             alt=""
    //             src="https://trackle.de/wp-content/uploads/2018/11/logo-trackle.png"
    //           />
    //         </div>
    //       </div>
    //
    //       <div className="col-5">
    //         <div className="logo-wrap">
    //           <img alt="" src="https://meihoff.de/assets/images/logo.png" />
    //         </div>
    //       </div>
    //
    //       <div className="col-5">
    //         <div className="logo-wrap">
    //           <p>
    //             Be the next
    //             <br /> happy customer. 😊
    //           </p>
    //         </div>
    //       </div>
    //     </div>
    //   </section>
    }
    </React.Fragment>
  );
}
}
export default Testemonials;
