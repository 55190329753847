import React from 'react';
import Page from './Page';
import Footer from './Footer';
var brandingText = "Die Ent­wick­lung ei­nes Un­ter­neh­mens­auf­tritts ist eine sehr kom­ple­xe An­ge­le­gen­heit. Bei gro­ßen De­si­gnagen­tu­ren kön­nen hier Prei­se von bis zu 50.000€ ent­ste­hen. Die An­for­de­run­gen an ein gu­tes Bran­ding sind für den Lai­en oft nur schwer ein­zu­schät­zen. Der ers­te Ein­druck ei­nes De­signs muss na­tür­lich stim­men, den­noch funk­tio­nie­ren lei­der vie­le De­signs an­schlie­ßend in der An­wen­dung nur sehr schlecht. Das Logo muss auf vie­len Hin­ter­grün­den und ver­schie­de­nen Ska­lie­run­gen funk­tio­nie­ren.";

var brandingText2 = "Dein Branding muss in enger Zusammenarbeit mit deinem Untenrehmen entstehen. Anders ist es nicht möglich. Das heißt, dass du dir für den Zeitraum des Projekts Zeit nimmst, um mit mir eng zusammen zu arbeiten und früh auf Designvorschläge zu reagieren. Im Gegenzug verzichte ich auf eine fixe Zahl von Korrekturschleifen. Nach dem von uns festgelegten Zeitraum (2-4 Wochen im Schnitt) sollte ein zufriedenstellendes Design entstanden sein. Bist du dennoch unzufrieden, können wir das Projekt gegen einen Aufpreis verlängern. Solltest du mir nicht vertrauen, und möchtest das Projekt abbrechen, erhälst du 50% des Preises zurück, kannst aber natürlich keine der entstanden Gestaltungen verwenden."

import brandingTextImage from "./assets/img/brandingErklaerung.png";

export default function Impressum(props) {
  return (
    <Page pageName="Branding"
          pageHero="Branding"
          pageStyle=""
          pageType="Dienstleistung"
    >
  <section className="Branding">
<div className="grid wrap -center">

      <div className="col-70_md-100 ">
      <h2>Darum gehts</h2>
        <p>{brandingText}</p>
        <p>Ge­ra­de für jun­ge Un­ter­neh­men und Star­tups emp­feh­le ich zu Be­ginn auf ein Mi­ni­mal Via­ble De­sign zu set­zen, statt auf ei­nen Schlaf das PER­FEK­TE De­sign zu fin­den. Das dann spä­ter ohne grö­ße­ren AUf­wand er­wei­tert wer­den kann. Auch da­für ist ein mi­ni­ma­lis­ti­sches, ein­präg­sa­mes, kla­res De­sign wich­tig.</p>
<figure className="textblock-img"><img className="img" src={brandingTextImage}/>
</figure>      <h4>Projektablauf</h4>
          <p>{brandingText2}</p>

      </div>
    </div>

    </section>

    </Page>
  );
}
