import React from "react";
import PropTypes from "prop-types";
import Link from "react-router-dom/Link";
import arrowUp from "./assets/img/arrow-up.svg";
import videoWebm from "./assets/img/landing.mp4";
import videoMp4 from "./assets/img/landing.mp4";
import videoMobile from "./assets/img/landingMobile.webm";
import videoMobileMp4 from "./assets/img/landingMobile.mp4";

import thinking from "./assets/img/sad.gif";
import ReactPlayer from 'react-player'
import phil from "./assets/img/miniportrait.jpg";
import philAtWork from "./assets/img/philAtWork.png";

import { Controller, Scene } from "react-scrollmagic";
import { Tween } from "react-gsap";
import MediaQuery from 'react-responsive'
import {motion, AnimatePresence} from 'framer-motion';
const transition = {duration: .3, ease: [0.43, 0.13, 0.23, 0.96]};
const transitionVector = {duration:.5, ease: [0.6, 0.01, .4, 0.9]};
const heroH1 ={
animate:{
  transition:{
    delayChildren: .3,
    staggerChildren: 0.04,
    staggerDirection: 1,
  }
}
}


const heroH1span = {
  initial: {
    y: -50
  },
  animate: {
    y:0,
    transition: {duration: 1, ...transition}
  }
}

class PageHero extends React.Component {



  render() {
            var path = './assets/img/';
            var name = 'crowdlanding';
            var imageFormat = ".mp4";
  var homeBubbleVariants;
  var trail;
  if(this.props.location ) {
    trail = this.props.location.trail;
    if (trail === "fromLeistungenToHome"){
  homeBubbleVariants ={
    initial:{
      scale:13
    },
    animate:{
      scale:1,
      transition:{...transitionVector}
    }
  }
}

}

  if (this.props.pageName === "Home")

      return (
        <div ref="heroRef" id="pageHero" className="pageHero pageHero-home">
              <div className="goldenRatio"></div>
              <h1 id="h1" className="huge-h1">
                <span className="h1-text">H</span>
                <span className="h1-text">e</span>
  <span className="h1-text">y</span>
              </h1>

<div className="vector-mask-wrap">
              <motion.div
              variants={homeBubbleVariants}


              className=" vector-mask">
                <div className="left"></div>
                <div className="middle">
                  <div className="top"></div>

                  <Link
                    to={{
                      pathname: "/Leistungen",
                      state: { trail: "fromHomeToLeistungen" }
                    }}
                  >
                  <div className="center">

                      <svg
                        id="Ebene_1"
                        className="bubble"
                        data-name="Ebene 1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 596 608"
                      >
                        <path
                          d="M0,0V608H596V0ZM298.46,550.19C162,550.19,51.33,439.55,51.33,303.07S162,56,298.46,56,545.58,166.59,545.58,303.07,434.94,550.19,298.46,550.19Z"
                          fill="white"
                        />
                      </svg>

                  </div>        </Link>

                  <div className="bottom">        <div className="shadow-wrap">
<div className="shadow"></div>
                          </div></div>
                </div>
                <div className="right"> </div>
              </motion.div>
</div>
<div
className="leistungen-teaser-wrap">

          <div className="leistungen-teaser">

          <MediaQuery minDeviceWidth={769}>
          <div className="video-wrap">
          <ReactPlayer playing={true}  playsinline={true}  style={{opacity:0}} muted={true} width='100%' height='auto' url={[videoWebm, videoMp4]} />
          </div>
          </MediaQuery>



          <MediaQuery maxDeviceWidth={768}>
            <div className="video-wrap">
              <ReactPlayer playing={true}  playsinline={true}  style={{opacity:0}} muted={true} width='100%' height='auto' url={[videoMobile, videoMobileMp4]} />
          </div> </MediaQuery>


          </div></div>
        </div>
      );
    if (this.props.pageName === "Leistungen") {
      return (
        <div ref="heroRef" className="leistungen-hero-wrap">
          <div id="pageHero" className="pageHero wrap grid">

          <div className="h1-wrap">
            <div className="textblock">
            <span>   {  this.props.context.state.translation.default.services.heroSpan}</span>
            <h1>     {  this.props.context.state.translation.default.services.heroh1}
            </h1>
            </div>
          </div>
          </div>
          <motion.div
          initial={{scale:1}}
          animate={{scale:13, transition:{...transitionVector}}}
className=" vector-mask">


  <div className="left"></div>
  <div className="middle">
    <div className="top"></div>

    <div className="center">
      <img
        alt="pfeil nach oben"
        className="interactionIndicator -click"
        src={arrowUp}
      />
      <Link
        to={{
          pathname: "/Leistungen",
          state: { trail: "fromHomeToLeistungen" }
        }}
      >

        <svg
          id="Ebene_1"
          className="bubble"
          data-name="Ebene 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 596 608"
        >
          <path
            d="M0,0V608H596V0ZM298.46,550.19C162,550.19,51.33,439.55,51.33,303.07S162,56,298.46,56,545.58,166.59,545.58,303.07,434.94,550.19,298.46,550.19Z"
            fill="white"
          />
        </svg>
      </Link>
    </div>
    <div className="bottom"></div>
  </div>
  <div className="right"> </div>
</motion.div>


<MediaQuery minDeviceWidth={769}>
<div className="video-wrap">
<Link
  to={{
    pathname: "/",
    state: { trail: "fromLeistungenToHome" }
  }}
>
<ReactPlayer playing={true}  playsinline={true}  muted={true} width='100%' height='auto' url={[videoWebm, videoMp4]} />

</Link>
</div>
</MediaQuery>



<MediaQuery maxDeviceWidth={768}>
  <div className="video-wrap  -mobile">

    <ReactPlayer playing={true}  playsinline={true}  muted={true} width='100%' height='auto' url={[videoMobile, videoMobileMp4]} />
</div> </MediaQuery>



       </div>
      );
    }
    if (this.props.pageName === "About") {
      return (
        <div ref="heroRef" className="about-hero-wrap">
          <div className="pageHero wrap grid ">

            <div  className="h1-wrap">
            <h1>
         {  this.props.context.state.translation.default.about.h1}
</h1>
                </div>
        </div>


        </div>
      );
    }
        if (this.props.pageName === "Kontakt") {
          return (
            <div ref="heroRef" className="about-hero-wrap">
              <div className="pageHero wrap grid ">
    <motion.div className="col-100_sm-100">
                <div className="h1-wrap">
                  <motion.h1 initial={{y:'30%', opacity: 0}}animate={{opacity:1, y:'0', transition:{ delay: 0, ...transition}}} >
                   {  this.props.context.state.translation.default.contact.h1}
</motion.h1>
                </div>
                </motion.div>

              </div>

            </div>
          );
        }
    if (this.props.pageName === "Projektanfrage") {
      return (
        <div ref="heroRef" className="about-hero-wrap">
          <div id="projektanfrage-hero" className="pageHero wrap grid ">
     <div className=" col-65_sm-100">
            <div className="h1-wrap">
            <motion.h1
            >
           {  this.props.context.state.translation.default.projectrequest.h1}

              </motion.h1>

<motion.h3 initial={{y:'30%', opacity: 0}}animate={{opacity:1, y:'0', transition:{ delay: 0, ...transition}}} >
           {  this.props.context.state.translation.default.projectrequest.h1subline}
</motion.h3>

            </div>
                </div>

<motion.div initial={{scale:.8}}animate={{ scale:1, transition:{ delay: 0.2, ...transition}}}  className="hero-absolute-img">
    <img
      className="img"
      alt="Das Foto eines blonden, jungen Mannes."
      src={philAtWork}
    />
  </motion.div>

      </div>
        </div>
      );
    }
  if (this.props.pageName === "error") {
    return (
      <div ref="heroRef" className="about-hero-wrap">
        <div className="pageHero wrap grid align-left-bottom">

        <div className="col-82">
          <img className="thinking" src={thinking}/>
          <h1><span>4</span><span className="zero">0</span><span>4</span></h1>

              </div>
        </div>

      </div>
    );
  }
  if (this.props.pageName === "Projekte") {
    return (
      null
    );
  }
     else {
      return (
         <div ref="heroRef" id="projectPageHero" className="pageHero align-left-top wrap grid">
<motion.div initial={{x:'100vw'}}animate={{opacity:1, x:'0', transition:{ delay: 0.2, ...transition}}}  className="col-100_sm-100">
             <h1   id="h1" className="huge-h1">
            <span style={{ display: "inline" }}>{this.props.pageTitle}</span>

          </h1>       </motion.div >
         </div >
      );
    }
  }
}

PageHero.propTypes = {
  pageTitle: PropTypes.string,
  pageName: PropTypes.string
};

export default PageHero;
