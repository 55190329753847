
import React from "react";
import { Controller, Scene } from "react-scrollmagic";
import Link from "react-router-dom/Link";
import Hyphenated from 'react-hyphen';
import de from 'hyphenated-de';
class Kontaktteaser extends React.Component {
render(){
  return (
    <React.Fragment>
  <section className="Kontaktteaser">
  <div className="wrap">
  <div className="grid -spaceBetween">
  <div className="col_xs-100 col-40">
  <h3> {  this.props.context.state.translation.default.foodfriends.h4}</h3>
  </div>
  <div className="col_xs-100 col-50">
  <p>  <Hyphenated language={de}>{  this.props.context.state.translation.default.foodfriends.h4p} </Hyphenated></p>
  <Link
    to={{
      pathname: "/Kontakt"
    }}
    className="btn-primary"
  >
{  this.props.context.state.translation.default.foodfriends.button}
  </Link>
  </div>
  </div>
  </div>
  </section>

    </React.Fragment>
  );
}
}
export default Kontaktteaser;
