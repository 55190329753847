import React from "react";
import Page from "./Page";
import ProjectNav from "./ProjectNav";
import Kontaktteaser from "./Kontaktteaser";
import { Tween, Timeline } from "react-gsap";
import { Controller, Scene } from "react-scrollmagic";
import imgProjectFoodfriends from "./assets/img/foodfriends.jpg";
import foodfriendsMockup from "./assets/img/projekte/foodfriends/foodfriends-mockup.png";

import foodfriendsMockupMobile from "./assets/img/projekte/foodfriends/foodfriends-mockup-mobile.png";
import foodfriendsPrint from "./assets/img/projekte/foodfriends/foodfriends-print.png";
import foodfriendsLogo from "./assets/img/projekte/foodfriends/foodfriends-logo.png";
import foodfriendsShirt from "./assets/img/projekte/foodfriends/foodfriends-shirt.png";
import foodfriendsVisitenkarte from "./assets/img/projekte/foodfriends/foodfriends-visitenkarte.png";
import foodfriendsWebseite from "./assets/img/projekte/foodfriends/foodfriends-webseite.png";
import { motion } from "framer-motion";
import Hyphenated from "react-hyphen";
import de from "hyphenated-de";

import ff1 from "./assets/img/ff-screen1.png";
import ff2 from "./assets/img/ff-home.png";
import fficon from "./assets/img/ff-icon.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import MediaQuery from "react-responsive";
import "react-lazy-load-image-component/src/effects/opacity.css";
import LazyLoad from "react-lazyload";
const transition = { duration: 0.7, ease: [0.6, 0.01, 0.4, 0.9] };
import { MyContext } from "./Context";

import { useElementProperties } from "react-element-size-and-position";
var imageY;

function vh(v) {
  var h = Math.max(
    document.documentElement.clientHeight,
    window.innerHeight || 0
  );
  return (v * h) / 100;
}

function vw(v) {
  var w = Math.max(
    document.documentElement.clientWidth,
    window.innerWidth || 0
  );
  return (v * w) / 100;
}

function vmin(v) {
  return Math.min(vh(v), vw(v));
}

function vmax(v) {
  return Math.max(vh(v), vw(v));
}

function Placeholder() {
  return <div>Test</div>;
}

var height;

const TransitionGroup = (props) => {
  let heroHeight = useElementProperties("projectPageHero");
  if (heroHeight) {
    heroHeight = heroHeight.height;
    let heroVariants;
    if (props.props.state) {
      heroVariants = {
        initial: {
          width: props.props.state.width,
          borderRadius: "0.5em",
          height: props.props.state.height,
          y: -heroHeight + props.props.state.imageY,
          x: props.props.state.imageX,
        },
        animate: {
          width: "100%",
          borderRadius: "0",
          x: 0,
          y: 0,
          height: "100%",
          transition: { delay: 0.2, ...transition },
        },
      };
    }

    return (
      <motion.div style={{ overflow: "hidden" }} variants={heroVariants}>
        <Controller>
          <Scene duration="250%" triggerHook={0.6}>
            <Timeline wrapper={<div className="parallax -displayDesktop" />}>
              <Tween
                position="0"
                from={{
                  yPercent: -30,
                }}
                to={{
                  yPercent: 20,
                }}
              >
                <figure className="lazy-load-image-background-wrap -displayDesktop">
                  <LazyLoadImage effect="opacity" src={foodfriendsMockup} />
                </figure>
              </Tween>
            </Timeline>
          </Scene>
        </Controller>
      </motion.div>
    );
  } else {
    return null;
  }
};

class Foodfriends extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <MyContext.Consumer>
        {(context) => (
          <Page
            pageName="foodfriends"
            pageType="Project"
            loaderText="🍔 foodfriends"
            pageHero="foodfriends"
            pageStyle="Project"
          >
            <div className="projectContent" style={{ paddingBottom: "20vmin" }}>
              <MediaQuery maxDeviceWidth={768}>
                <div className="lazy-load-image-background-wrap -displayMobile">
                  <LazyLoadImage
                    effect="opacity"
                    src={foodfriendsMockupMobile}
                  />
                </div>
              </MediaQuery>

              <MediaQuery minDeviceWidth={769}>
                <TransitionGroup props={this.props.location}></TransitionGroup>
              </MediaQuery>
              <section>
                <div className="wrap">
                  <div className="project-intro  ">
                    <div className=" grid ">
                      <div className=" col-28_sm-100 grid-top project-infos">
                        <div className="col-100_sm-50">
                          <div className="client-wrap ">
                            <h5>Client</h5>
                            <p className="">Foodfriends</p>
                          </div>
                          <div className="jahr-wrap">
                            <h5>Jahr</h5>
                            <p className="">2017</p>
                          </div>
                        </div>

                        <div className="scope-wrap col-100_sm-50">
                          <h5>Scope</h5>
                          <p className="">UX/UI Design</p>
                          <p className="">Rapid Prototyping</p>
                          <p>Branding</p>
                        </div>
                      </div>

                      <div className=" col-65_sm-100">
                        <p className="p-big">
                          {" "}
                          <Hyphenated language={de}>
                            {context.state.translation.default.foodfriends.h1}
                          </Hyphenated>
                        </p>
                        <p></p>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className="bigImage-section">
                <figure className="bigImage logo-image">
                  <LazyLoadImage effect="opacity" src={foodfriendsLogo} />
                </figure>
              </section>
              <section className="smallImages-section">
                <div className="wrap grid">
                  <div class="headline-wrap grid">
                    <div className="col-46_sm-100">
                      <p className="p-big">
                        {" "}
                        {context.state.translation.default.foodfriends.h2}
                      </p>
                    </div>
                    <div className="col-54_sm-100">
                      <p>
                        {" "}
                        <Hyphenated language={de}>
                          {" "}
                          {context.state.translation.default.foodfriends.p}{" "}
                        </Hyphenated>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="wrap grid img-grid">
                  <div className="col-50_sm-100">
                    <figure>
                      <LazyLoadImage
                        effect="opacity"
                        wrapperClassName="lazy-load-image-wrap"
                        src={foodfriendsShirt}
                      />
                    </figure>
                  </div>
                  <div className="col-50_sm-100">
                    <figure>
                      <LazyLoadImage
                        effect="opacity"
                        src={foodfriendsVisitenkarte}
                      />
                    </figure>
                  </div>

                  <div className="col-50_sm-100">
                    <figure>
                      <LazyLoadImage
                        effect="opacity"
                        src={foodfriendsWebseite}
                      />
                    </figure>
                  </div>
                  <div className="col-50_sm-100">
                    <figure>
                      <LazyLoadImage effect="opacity" src={foodfriendsPrint} />
                    </figure>
                  </div>
                </div>
              </section>

              <section>
                <div className="wrap grid">
                  <h2 className="col-80">
                    {" "}
                    {context.state.translation.default.foodfriends.h3}
                  </h2>
                </div>
                <div class="wrap-perspective mockups">
                  <div class="iphone">
                    <div class="infobox-wrap">
                      <div class="infobox">
                        <div class="infotext">UX Design</div>
                      </div>
                    </div>
                    <div class="device-screen">
                      <LazyLoadImage effect="opacity" src={ff1} />
                    </div>
                  </div>
                  <div class="app-icon">
                    <div class="infobox-wrap">
                      <div class="infobox">
                        <div class="infotext">Branding</div>
                      </div>
                    </div>{" "}
                    <LazyLoadImage effect="opacity" src={fficon} />
                  </div>
                  <div class="iphone">
                    <div class="infobox-wrap">
                      <div class="infobox">
                        <div class="infotext">UI Design</div>
                      </div>
                    </div>
                    <div class="device-screen">
                      <LazyLoadImage effect="opacity" src={ff2} />
                    </div>
                  </div>
                </div>
              </section>

              <Kontaktteaser context={context}></Kontaktteaser>
            </div>

            <ProjectNav context={context} pageName="Foodfriends"></ProjectNav>
          </Page>
        )}
      </MyContext.Consumer>
    );
  }
}

export default Foodfriends;
