import React from "react";
import Page from "./Page";

import ProjectNav from "./ProjectNav";
import { Tween, Timeline } from "react-gsap";
import { Controller, Scene } from "react-scrollmagic";
import imgProjectPickshare from "./assets/img/pickshare-mockup.png";
import hammerScreens from "./assets/img/projekte/hammerUndPartner/hammerScreens.jpg";
import hammerMockup from "./assets/img/projekte/hammerUndPartner/hammer-mockup.png";
import hammerMockupMobile from "./assets/img/projekte/hammerUndPartner/hammer-mockup-mobile.png";

import { MyContext } from "./Context";

import Hyphenated from "react-hyphen";
import de from "hyphenated-de";
import hammerVideo from "./assets/img/projekte/hammerUndPartner/hammer.webm";
import hammerVideoMp4 from "./assets/img/projekte/hammerUndPartner/hammer.webm";
import ReactPlayer from "react-player";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/opacity.css";
class HammerUndPartner extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <MyContext.Consumer>
        {(context) => (
          <Page
            pageName="HammerUndPartner"
            pageType="Project"
            pageHero="Hammer&Partner"
            loaderText="💼 Hammer&Partner"
            pageStyle="Project"
          >
            <section
              className="projectContent"
              style={{ paddingBottom: "20vmin" }}
            >
              <div className="lazy-load-image-background-wrap -displayMobile">
                <LazyLoadImage
                  effect="opacity"
                  className="-displayMobile"
                  src={hammerMockupMobile}
                />
              </div>
              <Controller>
                <Scene duration="250%" triggerHook={0.6}>
                  <Timeline
                    wrapper={<div className="parallax -displayDesktop" />}
                  >
                    <Tween
                      position="0"
                      from={{
                        yPercent: -30,
                      }}
                      to={{
                        yPercent: 20,
                      }}
                    >
                      <figure className="lazy-load-image-background-wrap -displayDesktop">
                        <LazyLoadImage effect="opacity" src={hammerMockup} />
                      </figure>
                    </Tween>
                  </Timeline>
                </Scene>
              </Controller>

              <section>
                <div className="wrap">
                  <div className="project-intro grid">
                    <div className=" col-28_sm-100 grid-top project-infos">
                      <div className="col-100_sm-50">
                        <div className="client-wrap ">
                          <h5>Client</h5>
                          <p className="">Hammer&Partner</p>
                        </div>
                      </div>

                      <div className="scope-wrap col-100_sm-50">
                        <h5>Scope</h5>
                        <p className="">Webdesign</p>
                      </div>
                    </div>

                    <div className=" col-65_sm-100">
                      <p className="p-big">
                        <Hyphenated language={de}>
                          {context.state.translation.default.hammer.h1}
                        </Hyphenated>
                      </p>
                    </div>
                  </div>
                </div>

                <div className="fullscreenVideo wrap">
                  <div className="video-wrap">
                    <Controller>
                      <Scene
                        duration={600}
                        triggerElement="#mainVid-wrap"
                        triggerHook={0.3}
                        classToggle="-active"
                      >
                        {(progress, event) => {
                          if (progress > 0) {
                            var isPlaying = true;
                          } else {
                            var isPlaying = false;
                          }
                          if (progress >= 1) {
                            var isPlaying = false;
                          }
                          return (
                            <ReactPlayer
                              controls={false}
                              ref={this.ref}
                              id="mainVid-wrap"
                              playing={isPlaying}
                              muted={true}
                              width="100%"
                              height="100%"
                              url={[hammerVideo, hammerVideoMp4]}
                            />
                          );
                        }}
                      </Scene>
                    </Controller>
                  </div>
                </div>
              </section>
            </section>

            {
              // <ProjectFooter>
              // </ProjectFooter>
            }
            <ProjectNav
              context={context}
              pageName="HammerUndPartner"
            ></ProjectNav>
          </Page>
        )}
      </MyContext.Consumer>
    );
  }
}
export default HammerUndPartner;
