import React from 'react';
import Canvas from './Canvas';
class Animation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      angle: 0,
      randomX: Math.floor(Math.random() * (window.width - 0)) + 0,
      randomY: Math.floor(Math.random() * (window.height - 0)) + 0

     };
    this.updateAnimationState = this.updateAnimationState.bind(this);
  }

  componentDidMount() {
    this.rAF = requestAnimationFrame(this.updateAnimationState);
  }

  updateAnimationState() {
    this.setState(prevState => ({ angle: prevState.angle + 1 }));
    this.rAF = requestAnimationFrame(this.updateAnimationState);
  }

  componentWillUnmount() {
    cancelAnimationFrame(this.rAF);
  }

  render() {
    if(this.props.myState){
          return <Canvas randomX={this.state.randomX}
                        randomY={this.state.randomY}
          />;
    }
    else{
      return null
    }

  }
}
export default Animation
