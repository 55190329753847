import React from "react";
import Page from "./Page";
import Footer from "./Footer";
import hyper from "./assets/img/hyperactive/hans-klein.gif";
import imgProjectHuman2 from "./assets/img/heyhuman.png";
import imgProjectRhenus from "./assets/img/rhenus.jpg";
import imgProjectFoodfriends from "./assets/img/foodfriends.jpg";

import thumb1 from "./assets/img/projekte/thumbnails/Hyperaktivität.gif";
import thumb2 from "./assets/img/projekte/thumbnails/Foodfriends.png";

import thumb3 from "./assets/img/projekte/thumbnails/autarq.png";

import thumb5 from "./assets/img/projekte/thumbnails/pfoten.png";
import thumb4 from "./assets/img/projekte/thumbnails/Caera.png";
import thumb6 from "./assets/img/projekte/thumbnails/Hammer&Partner.png";

import { motion, AnimatePresence } from "framer-motion";
import Link from "react-router-dom/Link";
import { Controller, Scene } from "react-scrollmagic";
import { Tween, Timeline, SplitWords } from "react-gsap";
const transition = { duration: 0.3, ease: [0.43, 0.13, 0.23, 0.96] };
const projectNames = {
  animate: {
    transition: {
      delayChildren: 0.1,
      staggerChildren: 0.04,
      staggerDirection: 1,
    },
  },
};

const projectName = {
  initial: {
    y: "2em",
    opacity: 0,
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: { duration: 1, ...transition },
  },
};

class HoverList extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  constructor(props) {
    super(props);
    this.itemRef = [];
    this.state = {
      activeItem: "test",
    };
  }

  handleMouseLeave(index) {
    this.setState({
      activeItem: "keins",
    });
  }

  handleMouseEnter(index) {
    this.setState({
      activeItem: index,
    });
  }

  render() {
    var items = this.props.items.map((name, index) => {
      if (index == 0) {
        var freiesHeadline = "Freie Projekte";
      }
      var link = name;
      if (name == "Hammer&Partner") {
        link = "hammerundpartner";
      }
      if (name == "Hyperaktivität") {
        link = "Hyperactive";
      }

      return (
        <React.Fragment>
          <motion.li
            style={{ width: "100%" }}
            variants={projectName}
            ref={(itemRef) => (this.itemRef[index] = itemRef)}
            key={index}
            className={index}
            onMouseEnter={() => this.handleMouseEnter(index)}
            onMouseLeave={() => this.handleMouseLeave(index)}
          >
            <Link
              to={{
                pathname: "/" + link,
                state: { trail: "fromWorks" },
              }}
            >
              {name}
            </Link>
          </motion.li>
        </React.Fragment>
      );
    });
    var images = this.props.items.map((name, index) => {
      var status = false;
      var image;
      if (this.state.activeItem == index) {
        status = true;
      } else {
        status = false;
      }
      if (index == 0) image = thumb1;
      if (index == 1) image = thumb2;
      if (index == 2) image = thumb3;
      if (index == 3) image = thumb4;
      if (index == 4) image = thumb5;
      if (index == 5) image = thumb6;
      return (
        <img key={index} className={status ? "-active" : ""} src={image} />
      );
    });

    return (
      <ul className={"col-md-80 projects-list " + this.state.activeItem}>
        {items}

        <div className="projectlist-images">
          <Controller>
            <Scene
              duration={200}
              triggerElement=".projectlist-images"
              triggerHook={0.2}
              pin
            >
              <div>{images}</div>
            </Scene>
          </Controller>
        </div>
      </ul>
    );
  }
}
{
  //"Hyperaktivität", "Pleasant Distance", "AnymotionGROUP", "Foodfriends", "Pickshare", "Hammer&Partner", "Bekumoo"
}
var projectTitles = [
  "Hyperaktivität",
  "Foodfriends",
  "Autarq",
  "Caera",
  "Pfotenphysio",
  "Hammer&Partner",
];

export default function Works(props) {
  return (
    <Page
      pageName="Projekte"
      pageHero="Projekte"
      pageStyle=""
      pageType="Works"
      loaderText="😎 Projekte"
    >
      <section className="allprojects">
        {
          // <div className="select">
          // <a className="-active">Kundenprojekte</a>
          // <a>Freie Arbeiten</a>
          // </div>
        }
        <div className="wrap grid">
          <motion.div style={{ width: "100%" }} variants={projectNames}>
            <HoverList items={projectTitles}></HoverList>
          </motion.div>
        </div>
      </section>
    </Page>
  );
}
