import React from "react";
import { Tween } from "react-gsap";
import Link from "react-router-dom/Link";
import illuMe from "./assets/img/miniportrait.jpg";
import ReactPlayer from 'react-player'



class MenuModal extends React.Component {
  componentDidMount() {
    this.setState({
      isOpen: this.props.isOpen,
      isCopied: false
    });
    document.getElementById("menuInfos").className =
        "menuInfos pageload";
  }

  notification(isCopied){


    if(isCopied){
        setTimeout(function(){
             this.setState({isCopied:false});
        }.bind(this),2000);

        return(
          <div className="notification">
{  this.props.context.state.translation.default.menuModal.successMessage}
          </div>
        )
    }

  }
  menu(menuClasses) {
    var homeLink;
    var leistungenLink;
        var aboutLink;
    var worksLink;
    var activeLink;
    var kontaktLink;
    if(this.props.activePage == "Home"){
      homeLink = true;
    }
      else{
              homeLink = false;
      }
  if(this.props.activePage == "Leistungen"){
    leistungenLink = true;
  }
  else{
        leistungenLink = false;
  }
  if(this.props.activePage == "About"){
    aboutLink = true;
  }
  else{
        aboutLink = false;
  }
    if(this.props.activePage == "Kontakt"){
      kontaktLink = true;
    }
    else{
          kontaktLink = false;
    }
    if(this.props.activePage == "About"){
      aboutLink = true;
    }
    else{
          aboutLink = false;
    }
    if(this.props.activePage == "Projekte"){
      worksLink = true;
    }
      else{
          worksLink = false;
      }

    return (
      <div  id="menuInfos" className={menuClasses}>
      <div className="language-menu">
      <a className={this.props.context.state.language === 'de' ? "-active" : ""}
      onClick={this.props.context.changeLanguage}
      data-language="de"
      >DE</a>
      <a className={this.props.context.state.language === 'en' ? "-active" : ""}
      onClick={this.props.context.changeLanguage}
      data-language="en"
      >EN</a>
      </div>
        <div className="menuInfos-wrap">

          <div className="wrap grid-spaceAround">
            <div className="col-35_xs-100 ">
              <ul>


            <li className={worksLink ? "-active" : ""}>
      <Link
        to={{
          pathname: "/Works",
          state: { trail: "fromMenu" }
        }}
      >
{  this.props.context.state.translation.default.menuModal.works}
      </Link>


    </li>

<li className={leistungenLink ? "-active" : ""}>
<Link
to={{
pathname: "/Leistungen",
          state: { trail: "fromHomeToLeistungen" }
}}
>
{  this.props.context.state.translation.default.menuModal.services}
</Link>
</li>
<li className={aboutLink ? "-active" : ""}>
<Link
to={{
pathname: "/About",
          state: { trail: "fromMenu" }
}}
>
{  this.props.context.state.translation.default.menuModal.about}
</Link>
</li>

              </ul>
            </div>{" "}

            <div className="col-60_xs-100 ">
              <div className="grid-spaceAround kontaktInfo-wrap">
                <div className="col_xs-40 col-25" style={{position:'relative'}}>

                  <div className="img-wrap">


                    <img
                      className="img"
                      alt="Das Foto eines blonden, jungen Mannes."
                      src={illuMe}
                    />
                  </div>
                </div>
                <div className="menu-kontaktInfos col_xs-100 col-75">
                  <div

                    className="menuInfos-link dropdown"
                  ><button>  <svg
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      version="1.1"
                      x="0px"
                      y="0px"
                      width="100%"
                      stroke="rgb(120, 132, 157)"
                      strokeWidth=".6"
                      height="100%"
                      fill="rgb(120, 132, 157)"
                      viewBox="0 0 35 35"
                      enableBackground="new 0 0 35 35"
                      xmlSpace="preserve"
                    >
                      <path d="M33.5 24V11c0-2.757-2.243-5-5-5h-22c-2.757 0-5 2.243-5 5v13c0 2.757 2.243 5 5 5h22c2.757 0 5-2.243 5-5zm-5-16c.462 0 .894.114 1.285.3L18.206 19.878a1.023 1.023 0 0 1-1.413 0L5.215 8.301C5.605 8.113 6.038 8 6.5 8h22zm-25 16V11c0-.462.114-.894.3-1.285l11.578 11.578c.567.566 1.32.878 2.122.878s1.555-.312 2.121-.878L31.2 9.715c.188.39.301.823.301 1.285v13c0 1.654-1.346 3-3 3h-22c-1.654 0-3-1.346-3-3z" />
                    </svg>
                    Philipp.Wienes@einfach.es</button>

                    <div className="dropdown-menu">
				    	<a onClick={() => {
                navigator.clipboard.writeText("Philipp.Wienes@einfach.es");
                this.setState ({ isCopied: true})
                }
                }

                className="dropdown-item clipboard"><svg
  strokeWidth="0"
                width="18" height="18" xmlns="http://www.w3.org/2000/svg"><g fill="#F5C15D" fillRule="nonzero"><path d="M15.08 5.794H8.304a2.51 2.51 0 00-2.51 2.51v6.776a2.51 2.51 0 002.51 2.51h6.776a2.51 2.51 0 002.51-2.51V8.304a2.51 2.51 0 00-2.51-2.51zm-6.776 1.5h6.776c.558 0 1.01.452 1.01 1.01v6.776a1.01 1.01 0 01-1.01 1.01H8.304a1.01 1.01 0 01-1.01-1.01V8.304c0-.558.452-1.01 1.01-1.01z"></path><path d="M9.712.25c1.235 0 2.246.96 2.329 2.174l.005.16v.792a.75.75 0 01-1.493.102l-.007-.102v-.792a.834.834 0 00-.73-.828l-.104-.006H2.584a.834.834 0 00-.828.73l-.006.104v7.128c0 .425.318.776.73.828l.104.006h.792a.75.75 0 01.102 1.493l-.102.007h-.792A2.334 2.334 0 01.255 9.872l-.005-.16V2.584c0-1.235.96-2.246 2.174-2.329l.16-.005h7.128z"></path></g></svg> {  this.props.context.state.translation.default.menuModal.copy}</a>
				    	<div className="dropdown-divider"></div>
				    	<a className="dropdown-item" href="mailto:Philipp.Wienes@einfach.es"><svg   strokeWidth="0" width="18" height="15" xmlns="http://www.w3.org/2000/svg"><g fill="#F5C15D" fillRule="nonzero"><path d="M15.256.25H2.584A2.339 2.339 0 00.25 2.584v9.504a2.339 2.339 0 002.334 2.334h12.672a2.339 2.339 0 002.334-2.334V2.584A2.339 2.339 0 0015.256.25zM2.584 1.75h12.672c.457 0 .834.377.834.834v9.504a.839.839 0 01-.834.834H2.584a.839.839 0 01-.834-.834V2.584c0-.457.377-.834.834-.834z"></path><path d="M16.41 1.97a.75.75 0 01.946 1.158l-.086.07-7.92 5.544a.75.75 0 01-.76.06l-.1-.06L.57 3.198a.75.75 0 01.765-1.285l.095.057 7.49 5.242 7.49-5.242z"></path></g></svg> {  this.props.context.state.translation.default.menuModal.mailtool}</a>
					</div>
                  </div>

                  <Link
                    to={{
                      pathname: "/Projektanfrage",
                      state: { trail: "fromMenu" }
                    }}
                    className="btn-primary btn"
                  >

                    <span> {  this.props.context.state.translation.default.menuModal.formular} </span>
                    <span className="btn-bg"></span>
                          <i>
                            <svg
                              stroke="rgba(255,255,255,0.6)"
                              strokeWidth="0.5"
                              fill="rgba(255,255,255,0.6)"
                              width="26px"
                              height="17px"
                              id="Ebene_1"
                              data-name="Ebene 1"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 21.43 15.28"
                            >
                              <title>iconmonstr-arrow-right-thin</title>
                              <path
                                d="M12.26,19.14,11.32,18l7-5.79H0V10.77H18.31L11.31,5l.95-1.12,9.17,7.62Z"
                                transform="translate(0 -3.86)"
                              />
                            </svg>
                            <svg
                              stroke="rgba(255,255,255,0.6)"
                              strokeWidth="0.5"
                              fill="rgba(255,255,255,0.6)"
                              width="26px"
                              height="17px"
                              id="Ebene_1"
                              data-name="Ebene 1"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 21.43 15.28"
                            >
                              <title>iconmonstr-arrow-right-thin</title>
                              <path
                                d="M12.26,19.14,11.32,18l7-5.79H0V10.77H18.31L11.31,5l.95-1.12,9.17,7.62Z"
                                transform="translate(0 -3.86)"
                              />
                            </svg>
                          </i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    if (!this.props.isOpen) {

      document.body.classList.remove("menuModal-open");

      return (
          <React.Fragment>
          {this.menu("menuInfos closed ")}
                </React.Fragment>
      );
    } else {
      document.body.classList.add("menuModal-open");

      return (
        <React.Fragment>

            {this.menu("menuInfos active")}

          {this.notification(this.state.isCopied)}
        </React.Fragment>

      );
    }
  }
}
export default MenuModal;
