import React from "react";
import Page from "./Page";

import ProjectNav from "./ProjectNav";
import { Tween, Timeline } from 'react-gsap';
import { Controller, Scene } from 'react-scrollmagic';
import imgProjectPickshare from "./assets/img/pickshare-mockup.png";
import imgProjectPickshareAbbinder from "./assets/img/pickshare-abbinder.jpg";
import pickshareScreens from './assets/img/projekte/pickshare/pickshareScreens.png';
import pickshareScreensMobile from './assets/img/projekte/pickshare/pickshareScreens-mobile.png';

import ReactPlayer from 'react-player'
import Hyphenated from 'react-hyphen';
import de from 'hyphenated-de';

import pickshareMenuVideo from './assets/img/projekte/pickshare/pickshareMenu.mp4';
import pickshareScreenflow from './assets/img/projekte/pickshare/pickshareScreenflow.mp4';
import pickshareOnboarding from './assets/img/projekte/pickshare/pickshareOnboarding.mp4';


import ps1 from './assets/img/pickshare01.png';
import ps2 from './assets/img/pickshare02.png';
import ps3 from './assets/img/pickshare03.png';

import 'react-lazy-load-image-component/src/effects/opacity.css';
import LazyLoad from 'react-lazyload';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import MediaQuery from 'react-responsive'

class Pickshare extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  render() {
    return (
      <Page
        pageName="Pickshare"
        pageType="Project"
        pageHero="Pickshare"
                      loaderText="📦 Pickshare"
        pageStyle="Project"
      >
        <section className="projectContent">
          <MediaQuery maxDeviceWidth={768}>
          <div className="lazy-load-image-background-wrap -displayMobile">
            <LazyLoadImage
            effect="opacity"
            className="-displayMobile"
            src={pickshareScreensMobile}
           /></div>
           </MediaQuery>

                 <MediaQuery minDeviceWidth={769}>
               <Controller>
              <Scene      duration="250%" triggerHook={0.6} >
            <Timeline
                    wrapper={<div className="parallax -displayDesktop" />}
                  >
             <Tween
                      position="0"
                      from={{
                        yPercent: -30,
                      }}
                      to={{
                          yPercent: 20,
                      }}
                    >
                       <figure className="lazy-load-image-background-wrap -displayDesktop">
                          <LazyLoadImage
                            effect="opacity"
                            src={pickshareScreens}
                           />
                       </figure>
           </Tween>
           </Timeline>

          </Scene>
          </Controller>
                     </MediaQuery>
          <section>
          <div className="wrap">
            <div className="project-intro grid">
              <div className=" col-28_sm-100 grid-top project-infos">

              <div className="col-100_sm-50">
              <div className="client-wrap ">
                <h5>Client</h5>
                <p className="">Caera, Beurer</p>
              </div>
              <div className="jahr-wrap">
        <h5>Jahr</h5>
            <p className="">2021</p>
      </div>
              </div>

      <div className="scope-wrap col-100_sm-50">
              <h5>Scope</h5>
              <p className="">UX/UI Design</p>
                  <p className="">Rapid Prototyping</p>
            </div>
      </div>
          <div className=" col-65_sm-100">
                {" "}

                <p className="p-big"><Hyphenated language={de}>
                  Ui Design für ein Startup, das den Paketversand neu denkt.
                  Flow is een muzikaal initiatief van DJ Franky Rizardo dat
                  begon in 2013 als wekelijkse radioshow op SLAM!FM. Het
                  initiatief is nu ontwikkeld tot een muzikale beweging met</Hyphenated>
                </p>

              </div>
            </div>

          </div>


                  <div class="wrap-perspective mockups">

                      <div class="iphone">
                          <div class="infobox-wrap">
                              <div class="infobox">
                                  <div class="infotext">
                                      UX Design
                                  </div>
                              </div>
                          </div>
                          <div class="device-screen">
                      <ReactPlayer  controls={false}  loop={true} ref={this.ref} playing={true} muted={true} width='100%' height='100%' url={pickshareMenuVideo}   />
                          </div>
                      </div>
                            <div class="iphone -second">
                              <div class="infobox-wrap">
                                  <div class="infobox">
                                      <div class="infotext">
                                          Prototyping
                                      </div>
                                  </div>
                              </div>
                                <div class="device-screen">
                                                    <ReactPlayer  controls={false}  loop={true} ref={this.ref} playing={true} muted={true} width='100%' height='100%' url={pickshareScreenflow}   />

                                </div>
                            </div>
                      <div class="iphone -third">
                          <div class="infobox-wrap">
                              <div class="infobox">
                                  <div class="infotext">
                                      UI Design
                                  </div>
                              </div>
                          </div>
                          <div class="device-screen">
                                                                              <ReactPlayer  controls={false}  loop={true} ref={this.ref} playing={true} muted={true} width='100%' height='100%' url={pickshareOnboarding}   />

                          </div>
                      </div>
                  </div>

          <figure className="bigImage">
            <img src={imgProjectPickshareAbbinder} alt="randomImage" />
          </figure>
          </section>

        </section>

        {
          // <ProjectFooter>
          // </ProjectFooter>
        }
        <ProjectNav pageName="Pickshare"></ProjectNav>
      </Page>
    );
  }
}
export default Pickshare;
