import React from "react";
import Page from "./Page";

import ProjectNav from "./ProjectNav";
import { Tween, Timeline } from 'react-gsap';
import { Controller, Scene } from 'react-scrollmagic';
import imgProjectPickshare from "./assets/img/pickshare-mockup.png";
import pfotenMockup from "./assets/img/projekte/pfotenphysio/pfoten-mockup.png";
import pfotenMockupMobile from "./assets/img/projekte/pfotenphysio/pfoten-mockup-mobile.png";

import LazyLoad from 'react-lazyload';
import Hyphenated from 'react-hyphen';
import de from 'hyphenated-de';
import pfotenVideoWebm from "./assets/img/projekte/pfotenphysio/pfotenphysio.webm";
import pfotenVideoMp4 from "./assets/img/projekte/pfotenphysio/pfotenphysio.mp4";
import pfotenphysioIntroWebm from "./assets/img/projekte/pfotenphysio/pfotenphysioIntro.webm";
import pfotenphysioIntroMp4 from "./assets/img/projekte/pfotenphysio/pfotenphysioIntro.mp4";

import { MyContext } from './Context';


import ReactPlayer from 'react-player';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';
class Pfotenphysio extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  render() {
    return (
      <MyContext.Consumer>
        {(context) => (
      <Page
        pageName="Pfotenphysio"
        pageType="Project"
        pageHero="PfotenPhysio"
        pageStyle="Project"
      >
        <section className="projectContent"  style={{paddingBottom: '20vmin'}}>
        <div className="lazy-load-image-background-wrap -displayMobile">
                          <LazyLoadImage
                             effect="opacity"
                             className="-displayMobile"
                             src={pfotenMockupMobile}
                             /></div>
             <Controller>
            <Scene      duration="250%" triggerHook={0.6} >
          <Timeline
                  wrapper={<div className="parallax -displayDesktop" />}
                >
           <Tween
                    position="0"
                    from={{
                      yPercent: -30,
                    }}
                    to={{
                        yPercent: 20,
                    }}
                  >
                  <figure className="lazy-load-image-background-wrap -displayDesktop">
                     <LazyLoadImage
                       effect="opacity"
                       src={pfotenMockup}
                      />
</figure>
         </Tween>
         </Timeline>

        </Scene>
        </Controller>

          <section>
          <div className="wrap">
            <div className="project-intro grid">
        <div className=" col-28_sm-100 grid-top project-infos">
        <div className="col-100_sm-50">
        <div className="client-wrap ">
          <h5>Client</h5>
          <p className="">Pfotenphysio</p>
        </div>

        </div>

<div className="scope-wrap col-100_sm-50">
        <h5>Scope</h5>
        <p className="">Webdesign</p>
        <p className="">Webentwicklung</p>
            <p className="">Art Direction</p>
      </div>

    </div>


          <div className=" col-65_sm-100">

                <p className="p-big"><Hyphenated language={de}>
                        {  context.state.translation.default.pfotenphysio.h1}
                      </Hyphenated>
                </p>

              </div>
            </div>

          </div>

          <div className="wrap">
          <figure> <LazyLoad height={200} offset={100}>
          <ReactPlayer  controls={false}   muted={true} ref={this.ref} playing={true} loop={true}  width='100%' height='100%' url={[pfotenphysioIntroMp4, pfotenphysioIntroWebm]}   /></LazyLoad>
          </figure>
                  <figure> <LazyLoad height={200} offset={100}>
                  <ReactPlayer  controls={false}   muted={true} ref={this.ref} playing={true} loop={true}  width='100%' height='100%' url={[pfotenVideoMp4, pfotenVideoWebm]}   /></LazyLoad>
                  </figure>

      </div>
          </section>

        </section>

        {
          // <ProjectFooter>
          // </ProjectFooter>
        }
        <ProjectNav context={context} pageName="Pfotenphysio"></ProjectNav>
      </Page>
    )}
  </MyContext.Consumer>
    );
  }
}
export default Pfotenphysio;
