import React from "react";
import PropTypes from "prop-types";
import Link from "react-router-dom/Link";
import { Tween, Timeline } from "react-gsap";
import { Controller, Scene } from "react-scrollmagic";
import { motion, AnimatePresence } from "framer-motion";
const transition = { duration: 0.3, ease: [0.43, 0.13, 0.23, 0.96] };

var nextPageLink;
var nextPageHeadline;
var buttonText;
var projectNavClasses = "ProjectNav";
var trail = "fromProjectToProject";
var buttonEN = "Next Project";
var buttonDE = "Nächstes Projekt";

import { withRouter } from "react-router-dom";

class ProjectNav extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hover: false,
      count: 0,
    };
  }

  render() {
    if (this.props.pageName === "Foodfriends") {
      nextPageLink = "Autarq";
      nextPageHeadline = "Autarq";
      projectNavClasses = "ProjectNav -rhenus noMargin-top";
      nextPageHeadline = nextPageLink;
    }
    if (this.props.context.state.language === "en") {
      buttonText = buttonEN;
    }
    if (this.props.context.state.language === "de") {
      buttonText = buttonDE;
    }

    if (this.props.pageName === "Hyperactive") {
      nextPageLink = "Foodfriends";
      projectNavClasses = "ProjectNav -foodfriends noMargin-top";
      nextPageHeadline = nextPageLink;
    }
    if (this.props.pageName === "Autarq") {
      nextPageLink = "caera";
      nextPageHeadline = "Caera";
      projectNavClasses = "ProjectNav -caera noMargin-top";
    }

    if (this.props.pageName === "caera") {
      nextPageLink = "Pfotenphysio";

      nextPageHeadline = "Pfoten Physio";
      projectNavClasses = "ProjectNav -pfotenPhysio noMargin-top";
    }

    if (this.props.pageName === "Pfotenphysio") {
      nextPageLink = "HammerUndPartner";

      nextPageHeadline = "Hammer&Partner";
      projectNavClasses = "ProjectNav -hammerUndPartner noMargin-top";
    }

    if (this.props.pageName === "HammerUndPartner") {
      nextPageLink = "ProjektAnfrage";

      if (this.props.context.state.language === "en") {
        nextPageHeadline = "There is still a place available here... 😏";
        buttonText = "Let's go!";
      }
      if (this.props.context.state.language === "de") {
        nextPageHeadline = "Hier ist noch ein Plätzchen frei... 😏";
        buttonText = "Los gehts!";
      }

      projectNavClasses = "ProjectNav -ProjektAnfrage noMargin-top";
    }

    return (
      <section className={projectNavClasses}>
        <Link
          to={{
            pathname: "/" + nextPageLink,
            state: { trail: trail },
          }}
          className="a-wrap"
        >
          <div
            className="projectnav-container"
            data-attribute={this.state.count}
          >
            <h2>
              <motion.div
                initial={{ x: "0" }}
                exit={{ x: "-100vw", transition: { ...transition } }}
              >
                {nextPageHeadline}
              </motion.div>
            </h2>

            <motion.div
              initial={{ x: "0" }}
              exit={{ x: "100vw", transition: { ...transition } }}
            >
              <Link
                to={{
                  pathname: "/" + nextPageLink,
                  state: { trail: trail },
                }}
                className="btn-huge btn"
              >
                <span>{buttonText}</span>{" "}
                <i>
                  <svg
                    fill="#c0c8d1"
                    width="26px"
                    height="16px"
                    id="Ebene_1"
                    data-name="Ebene 1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 21.43 15.28"
                  >
                    <title>iconmonstr-arrow-right-thin</title>
                    <path
                      d="M12.26,19.14,11.32,18l7-5.79H0V10.77H18.31L11.31,5l.95-1.12,9.17,7.62Z"
                      transform="translate(0 -3.86)"
                    />
                  </svg>
                  <svg
                    fill="#c0c8d1"
                    width="26px"
                    height="16px"
                    id="Ebene_1"
                    data-name="Ebene 1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 21.43 15.28"
                  >
                    <title>iconmonstr-arrow-right-thin</title>
                    <path
                      d="M12.26,19.14,11.32,18l7-5.79H0V10.77H18.31L11.31,5l.95-1.12,9.17,7.62Z"
                      transform="translate(0 -3.86)"
                    />
                  </svg>
                </i>
              </Link>{" "}
            </motion.div>
          </div>
        </Link>
      </section>
    );
  }
}
ProjectNav.propTypes = {
  pageName: PropTypes.string,
};
export default withRouter(ProjectNav);
