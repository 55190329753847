import React, { Component } from 'react'
export const MyContext = React.createContext()
import * as translationDE from './translations/de';
import * as translationEN from './translations/en';
var translation = translationDE;
import detectBrowserLanguage from 'detect-browser-language';
var activeLang = "";
var activeTranslation = "";
if(detectBrowserLanguage() == "de-DE"){
activeLang = 'de';
activeTranslation = translationDE;
}
else{
  activeLang = 'en';
  activeTranslation = translationEN;
}


export class LanguageHandler extends Component {

  state = {
    language: activeLang,
    translation: activeTranslation
  }
  changeLanguage = (e) => {
    this.setState({
      language: e.target.dataset.language
    })
    if(e.target.dataset.language == "de"){
      this.setState({
        language: e.target.dataset.language,
        translation : translationDE
      })
    };
    if(e.target.dataset.language == "en"){
      this.setState({
        language: e.target.dataset.language,
        translation: translationEN
      })
    };
  }


  render() {
    return (
      <MyContext.Provider value={{
        state: this.state,
        changeLanguage: this.changeLanguage,
      }}>
        {this.props.children}
      </MyContext.Provider>
    )
  }
}
