import React from "react";
import Page from "./Page";

import ProjectNav from "./ProjectNav";
import Kontaktteaser from "./Kontaktteaser";

import { Tween, Timeline } from 'react-gsap';
import { Controller, Scene } from 'react-scrollmagic';
import imgProjectCaera from "./assets/img/projekte/caera/caera-mockup.png";
import caeraScreens from  "./assets/img/projekte/caera/caera-mockup.png";
import caeraScreensMobile from  "./assets/img/projekte/caera/caera-mockup-mobile.png";
import LazyLoad from 'react-lazyload';
import caeraScreensApp from  "./assets/img/projekte/caera/caera-mockup-app.png";
import caeraVideoSection01 from  "./assets/img/projekte/caera/caera-floatingsection.mp4";
import caeraVid01 from  "./assets/img/projekte/caera/caera01.webm";
import caeraVid02 from  "./assets/img/projekte/caera/caera-2.webm";

import caeraVid04 from  "./assets/img/projekte/caera/caera-4.webm";
import caeraVid05 from  "./assets/img/projekte/caera/caera-5.webm";

import caeraVid01Mp4 from  "./assets/img/projekte/caera/caera01.mp4";
import caeraVid02Mp4 from  "./assets/img/projekte/caera/caera-2.mp4";

import caeraVid04Mp4 from  "./assets/img/projekte/caera/caera-4.mp4";
import caeraVid05Mp4 from  "./assets/img/projekte/caera/caera-5.mp4";


import ps1 from './assets/img/pickshare01.png';
import ps2 from './assets/img/pickshare02.png';
import ps3 from './assets/img/pickshare03.png';
import Hyphenated from 'react-hyphen';
import de from 'hyphenated-de';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import MediaQuery from 'react-responsive'
import {motion} from 'framer-motion';
import { MyContext } from './Context';

import { useElementProperties } from 'react-element-size-and-position'
const transition = {duration:.4, ease: [0.6, 0.01, .4, 0.9]};

function vh(v) {
  var h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
  return (v * h) / 100;
}

function vw(v) {
  var w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
  return (v * w) / 100;
}

function vmin(v) {
  return Math.min(vh(v), vw(v));
}

function vmax(v) {
  return Math.max(vh(v), vw(v));
}

var height;

const TransitionGroup = (props) =>  {
  let heroHeight = useElementProperties('projectPageHero');
  if(heroHeight){
    heroHeight = heroHeight.height;
    let heroVariants;
      if(props.props.state){
        heroVariants ={
         initial:{
           width: props.props.state.width,
           borderRadius: '0.5em',
           height: props.props.state.height,
           y: - (heroHeight) + props.props.state.imageY,
           x:props.props.state.imageX ,
         },
         animate:{
           width: '100%',
                 borderRadius: '0',
           x:0,
           y:0,
           height: '100%',
           transition: {delay: 0.2, ...transition}
         }
         }
      }
    return(
      <motion.div
      style={{overflow:'hidden'}}
          variants={heroVariants}
  >
  <Controller>
  <Scene      duration="250%" triggerHook={0.6} >
  <Timeline
     wrapper={<div className="parallax -displayDesktop" />}
     >
  <Tween
         position="0"
         from={{
           yPercent: -30,
         }}
         to={{
             yPercent: 20,
         }}
       ><figure  className="lazy-load-image-background-wrap -displayDesktop">
          <LazyLoadImage
            effect="opacity"
            src={caeraScreens}
           />
  </figure>
  </Tween>
  </Timeline>

  </Scene>
  </Controller>


      </motion.div>
    )
  }
  else{
    return(null)
  }


}

import ReactPlayer from 'react-player'
class Caera extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  render() {

    return (
      <MyContext.Consumer>
        {(context) => (
      <Page
        pageName="caera"
        pageType="Project"
        loaderText="⭐caera"
        pageHero="caera"
        pageStyle="Project"
      >


    <div className="projectContent" style={{paddingBottom: '20vmin'}}>
    <MediaQuery maxDeviceWidth={768}>
        <div className="lazy-load-image-background-wrap -displayMobile">

    <LazyLoadImage
    effect="opacity"
    className="-displayMobile"
    src={caeraScreensMobile}
   />

   </div>
       </MediaQuery>
             <MediaQuery minDeviceWidth={769}>
               <TransitionGroup props={this.props.location}>
               </TransitionGroup>
             </MediaQuery>
          <section>
          <div className="wrap">
            <div className="project-intro grid">
                  <div className=" col-28_sm-100 grid-top project-infos">
                  <div className="col-100_sm-50">
                  <div className="client-wrap ">
                    <h5>Client</h5>
                    <p className="">Caera, Beurer</p>
                  </div>
                  <div className="jahr-wrap">
            <h5>Jahr</h5>
                <p className="">2021</p>
          </div>
                  </div>

          <div className="scope-wrap col-100_sm-50">
                  <h5>Scope</h5>
                  <p className="">UX/UI Design</p>
                      <p className="">Rapid Prototyping</p>
                          <p className="">Art Direction</p>
            <p className="">3D Rendering</p>
                        <p className="">Creative Coding</p>
                            <p className="">Frontend</p>
                </div>

              </div>
            <div className=" col-65_sm-100">
                {" "}
<p className="p-big">  <Hyphenated language={de}>{  context.state.translation.default.caera.h1}
 </Hyphenated></p>



              </div>

            </div>

          </div>
                    </section>



<div className="wrap">


  <figure> <LazyLoad height={200} offset={100}>
  <ReactPlayer  controls={false}   muted={true} ref={this.ref} playing={true} loop={true}  width='100%' height='100%' url={[caeraVid01,caeraVid01Mp4]}   /></LazyLoad>
  </figure>



        <figure> <LazyLoad height={200} offset={100}>
<ReactPlayer  controls={false}   muted={true} ref={this.ref}  playing={true} loop={true}  width='100%' height='100%' url={[caeraVid04,caeraVid04Mp4]}   /></LazyLoad>
    </figure>

</div>

<section className="caseTestemonial">
<div className="wrap">
<div className="grid -spaceBetween">
<div className="col_xs-100 col-40">
<h3>Zusammenarbeit mit Philipp </h3>
<div className="author">
<figure><img src="https://profile-images.xing.com/images/8bb8ef85b66d877198367657ddcb439b-4/marlon-besuch.256x256.jpg"/> </figure><p >Marlon Busch<br/> CEO caera</p>

</div>
</div>
<div className="col_xs-100 col-60">
<p>  <Hyphenated language={de}>{  context.state.translation.default.caera.testemonial}   </Hyphenated></p>

</div>
</div>
</div>
</section>
<MediaQuery maxDeviceWidth={768}>
<div className="lazy-load-image-background-wrap -displayMobile">
<LazyLoadImage
effect="opacity"
className="-displayMobile"
src={caeraScreensApp}
/></div>
</MediaQuery>
 <MediaQuery minDeviceWidth={769}>

 <LazyLoad height={200} offset={100}>
 <Controller>

<Scene      duration="250%" triggerHook={0.6} >
<Timeline
      wrapper={<div className="parallax -displayDesktop" />}
    >
<Tween
        position="0"
        from={{
          yPercent: -30,
        }}
        to={{
            yPercent: 20,
        }}
      >

      <img
      src={caeraScreensApp}
      />


</Tween>
</Timeline>

</Scene>
</Controller>
 </LazyLoad>
      </MediaQuery>
<Kontaktteaser context={context}>
</Kontaktteaser>
        </div>

        {
          // <ProjectFooter>
          // </ProjectFooter>
        }
        <ProjectNav context={context} pageName="caera"></ProjectNav>
      </Page>
    )}
  </MyContext.Consumer>
    );
  }
}
export default Caera;
