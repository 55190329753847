import React from 'react';
import Page from './Page';
import Footer from './Footer';

import { MyContext } from './Context';

class Impressum extends React.Component {
  componentDidMount() {
        window.scrollTo(0, 0);
  }
render() {

  return (

          <MyContext.Consumer>
            {(context) => (
    <Page pageName="Impressum" pageHero="Impressum" pageStyle="">
  <section className="Impressum">
<div className="grid wrap">
      <div className="col-20">
      </div>
      <div className="col-70">
<p><strong>
 {  context.state.translation.default.datapolicy.h5}</strong></p>


<p>
Philipp Wienes<br/>
Bismarckstraße 157<br/>
28203 Bremen

<br/>
</p>
<h4>
 {  context.state.translation.default.impressum.h5}
</h4>
<h4>
 {  context.state.translation.default.impressum.h4}
</h4>
<p>
 {  context.state.translation.default.impressum.p1}</p>


<h4> {  context.state.translation.default.impressum.h42}</h4><p>
 {  context.state.translation.default.impressum.p2}
</p>
<h4> {  context.state.translation.default.impressum.h43}</h4><p>
 {  context.state.translation.default.impressum.p3}
</p>
<h4> {  context.state.translation.default.impressum.h44}</h4><p>
 {  context.state.translation.default.impressum.p4}
</p>


      </div>
    </div>

    </section>

    </Page>
  )}
</MyContext.Consumer>
  );
}
}
export default Impressum;
