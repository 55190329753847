import React, { useState, useEffect } from "react";
import Page from "./Page";
import hyperTeaserVideo from "./assets/img/hanskleinTeaser.webm";
import anymotionTeaser from "./assets/img/dominos-grey.webm";

import hyperTeaserVideoMp4 from "./assets/img/hanskleinTeaser.mp4";
import anymotionTeaserMp4 from "./assets/img/dominos-grey.mp4";

import thumbnailAnymotionMobile from "./assets/img/thumbnail-anymotion-mobile.png";

import foodfriendsMockup from "./assets/img/projekte/foodfriends/foodfriends-mockup-small.png";
import ScrollToTop from "./ScrollToTop";

import illuEntwicklung from "./assets/img/illuEntwicklung.svg";
import illuDesign from "./assets/img/illuDesign.svg";
import thumbnailHyperMobile from "./assets/img/hyperactive/thumbnail-hyper-mobile.png";
import LazyLoad from "react-lazyload";
import Hyphenated from "react-hyphen";
import de from "hyphenated-de";
import MediaQuery from "react-responsive";
import imgProjectcaera from "./assets/img/projekte/caera/caera-mockup-small.png";
import { motion } from "framer-motion";
import Link from "react-router-dom/Link";
import { Controller, Scene } from "react-scrollmagic";
import { Tween } from "react-gsap";
const transition = { duration: 0.6, ease: [0.43, 0.13, 0.23, 0.96] };
import { useElementProperties } from "react-element-size-and-position";

const scrollTop = () => {
  window.scrollTo({ top: 0, behavior: "smooth" });
};
import { MyContext } from "./Context";

const Home = () => {
  let hyperImageProps = useElementProperties("hyperImage");
  let ffImageProps = useElementProperties("ffImage");
  let caeraImageProps = useElementProperties("caeraImageProps");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <MyContext.Consumer>
      {(context) => (
        <Page
          message="grow"
          pageName="Home"
          loaderText="einfach."
          pageHero="Hej.."
        >
          <section className={context.state.language + " home-intro"}>
            <div className="wrap grid home-intro-wrap">
              <div data-push-left="off-20_xs-0" className="col-80_xs-100">
                {
                  //  Ich gestalte und entwickle intuitive Interaktionen und starke Brandings für digitale Produkte und Webseiten.
                  //  Einfach: digitale Erlebnisse mit viel ❤️
                  //  Ich gestalte und entwickle intuitive Interaktionen und starke Brandings. Kurz: einfache, digitale Produkte und Webseiten mit viel ❤️ und 🧠
                  //Interaktive Designlösungen für digitale Produkte und Webseiten. Einfach. Attraktiv. Intuitiv.
                  //    Meine Arbeiten verbinden die Welt der digitalen Medien und des Grafikdesigns durch den innovativen Einsatz
                  //  von Technologie. Interaktive Designlösungen für digitale Produkte, Webseiten und Medienkunst aus Bremen.
                  //Ich reduziere komplexe Zusammenhänge zu einfachen, intuitiv bedienbaren digitalen Erlebnissen mit viel ❤️.
                }
                <div>
                  <h2>{context.state.translation.default.home.h1}</h2>
                  <span>
                    Interaction Design <i className="f-gray">•</i> Creative
                    Development
                  </span>
                </div>
              </div>{" "}
            </div>
          </section>
          <section className="projects">
            <div className="wrap ">
              <Controller>
                <Scene
                  classToggle="-active"
                  triggerElement={".hyperactive-preview"}
                  triggerHook={0.52}
                >
                  <div className="col-100_sm-100  hyperactive-preview projectPreview grid-noGutter">
                    <Controller>
                      <Scene
                        duration={400}
                        triggerElement={
                          ".hyperactive-preview .projectPreview-title p"
                        }
                        triggerHook={0}
                      >
                        <div className="col-50_sm-100 projectPreview-text  grid-verticalCentered">
                          <motion.div className="projectPreview-title">
                            <Link
                              to={{
                                pathname: "/Hyperactive",
                                state: {
                                  trail: "fromHomeToHyperactive",
                                  imageX: hyperImageProps
                                    ? hyperImageProps.left
                                    : 0,
                                  imageY: hyperImageProps
                                    ? hyperImageProps.top
                                    : 0,
                                  width: hyperImageProps
                                    ? hyperImageProps.width
                                    : 0,
                                  height: hyperImageProps
                                    ? hyperImageProps.height
                                    : 0,
                                },
                              }}
                            >
                              <h3>
                                {
                                  context.state.translation.default.home
                                    .Hyperactivity
                                }
                              </h3>

                              <p>New Media Art</p>
                            </Link>
                          </motion.div>
                        </div>
                      </Scene>
                    </Controller>
                    <Controller>
                      <Scene
                        duration={400}
                        triggerElement={".hyperactive-preview"}
                        triggerHook={0.7}
                      >
                        {(progress) => (
                          <Tween
                            from={{
                              transform: "rotateY(5deg) rotateZ(-3deg)",
                            }}
                            to={{
                              transform: "rotateY(0deg) rotateZ(0deg)",
                            }}
                            ease="ease"
                            totalProgress={progress}
                            paused
                          >
                            <div className="col-50_sm-100  projectPreview-image">
                              <Link
                                to={{
                                  pathname: "/Hyperactive",
                                  state: {
                                    trail: "fromHomeToHyperactive",
                                    imageX: hyperImageProps
                                      ? hyperImageProps.left
                                      : 0,
                                    imageY: hyperImageProps
                                      ? hyperImageProps.top
                                      : 0,
                                    width: hyperImageProps
                                      ? hyperImageProps.width
                                      : 0,
                                    height: hyperImageProps
                                      ? hyperImageProps.height
                                      : 0,
                                  },
                                }}
                                className="zIndex image-wrap"
                              >
                                <motion.video
                                  id="hyperImage"
                                  transition={transition}
                                  whileHover={{ scale: 1.1 }}
                                  autoPlay
                                  loop
                                  muted
                                >
                                  <source
                                    src={hyperTeaserVideoMp4}
                                    type="video/mp4"
                                  />
                                  <source
                                    src={hyperTeaserVideo}
                                    type="video/ogg"
                                  />
                                </motion.video>
                              </Link>

                              <div
                                className="projektImage-shadow"
                                alt="hyper"
                              ></div>
                            </div>
                          </Tween>
                        )}
                      </Scene>
                    </Controller>
                  </div>
                </Scene>
              </Controller>

              <Controller>
                <Scene
                  classToggle="-active"
                  triggerElement={".foodfriends-preview"}
                  triggerHook={0.52}
                >
                  <div className="col-100_sm-100   foodfriends-preview projectPreview grid-noGutter">
                    <Controller>
                      <Scene
                        duration={400}
                        triggerElement={
                          ".foodfriends-preview .projectPreview-title p"
                        }
                        triggerHook={0}
                      >
                        <motion.div className="projectPreview-title col-50_sm-100 -flexStart">
                          <Link
                            to={{
                              pathname: "/Foodfriends",
                              state: {
                                trail: "fromHomeToFoodfriends",
                                imageX: ffImageProps ? ffImageProps.left : 0,
                                imageY: ffImageProps ? ffImageProps.top : 0,
                                width: ffImageProps ? ffImageProps.width : 0,
                                height: ffImageProps ? ffImageProps.height : 0,
                              },
                            }}
                            className={"projectPreview-title"}
                          >
                            <h3>foodfriends</h3>
                            <p>Branding, Interfacedesign</p>
                          </Link>
                        </motion.div>
                      </Scene>
                    </Controller>
                    <Controller>
                      <Scene
                        duration={400}
                        triggerElement={".foodfriends-preview"}
                        triggerHook={0.7}
                      >
                        {(progress) => (
                          <Tween
                            from={{
                              transform: "rotateY(5deg) rotateZ(-3deg)",
                            }}
                            to={{
                              transform: "rotateY(0deg) rotateZ(0deg)",
                            }}
                            ease="ease"
                            totalProgress={progress}
                            paused
                          >
                            <div className="col-50_sm-100 projectPreview-image">
                              <Link
                                to={{
                                  pathname: "/foodfriends",
                                  state: {
                                    trail: "fromHomeToFoodfriends",
                                    imageX: ffImageProps
                                      ? ffImageProps.left
                                      : 0,
                                    imageY: ffImageProps ? ffImageProps.top : 0,
                                    width: ffImageProps
                                      ? ffImageProps.width
                                      : 0,
                                    height: ffImageProps
                                      ? ffImageProps.height
                                      : 0,
                                  },
                                }}
                                className="zIndex image-wrap"
                              >
                                <motion.img
                                  whileHover={{ scale: 1.1 }}
                                  transition={transition}
                                  src={foodfriendsMockup}
                                  alt="Foodfriends"
                                  id="ffImage"
                                ></motion.img>
                              </Link>
                              <div
                                className="projektImage-shadow"
                                alt="Foodfriends"
                              ></div>
                            </div>
                          </Tween>
                        )}
                      </Scene>
                    </Controller>
                  </div>
                </Scene>
              </Controller>

              <Controller>
                <Scene
                  classToggle="-active"
                  triggerElement={".caera-preview"}
                  triggerHook={0.52}
                >
                  <div className="col-100_sm-100 caera-preview  projectPreview grid-noGutter">
                    <Controller>
                      <Scene
                        duration={400}
                        triggerElement={
                          ".caera-preview .projectPreview-title p"
                        }
                        triggerHook={0}
                      >
                        <div className="col-50_sm-100 projectPreview-text -flexStart">
                          <Link
                            to={{
                              pathname: "/Caera",
                              state: {
                                trail: "fromHomeToCaera",
                                imageX: caeraImageProps
                                  ? caeraImageProps.left
                                  : 0,
                                imageY: caeraImageProps
                                  ? caeraImageProps.top
                                  : 0,
                                width: caeraImageProps
                                  ? caeraImageProps.width
                                  : 0,
                                height: caeraImageProps
                                  ? caeraImageProps.height
                                  : 0,
                              },
                            }}
                            className={"projectPreview-title"}
                          >
                            <h3>Caera</h3>
                            <p>
                              {
                                context.state.translation.default.home
                                  .CaeraScope
                              }
                            </p>
                          </Link>
                        </div>
                      </Scene>
                    </Controller>
                    <Controller>
                      <Scene
                        duration={400}
                        triggerElement={".caera-preview"}
                        triggerHook={0.7}
                      >
                        {(progress) => (
                          <Tween
                            from={{
                              transform: "rotateY(-5deg) rotateZ(3deg)",
                            }}
                            to={{
                              transform: "rotateY(0deg) rotateZ(0deg)",
                            }}
                            ease="ease"
                            totalProgress={progress}
                            paused
                          >
                            <div
                              className="col-50_sm-100  projectPreview-image"
                              id="caeraImageProps"
                            >
                              <Link
                                to={{
                                  pathname: "/Caera",
                                  state: {
                                    trail: "fromHomeToCaera",
                                    imageX: caeraImageProps
                                      ? caeraImageProps.left
                                      : 0,
                                    imageY: caeraImageProps
                                      ? caeraImageProps.top
                                      : 0,
                                    width: caeraImageProps
                                      ? caeraImageProps.width
                                      : 0,
                                    height: caeraImageProps
                                      ? caeraImageProps.height
                                      : 0,
                                  },
                                }}
                                className="zIndex image-wrap"
                              >
                                <LazyLoad height={400} offset={100}>
                                  <motion.img
                                    whileHover={{ scale: 1.1 }}
                                    transition={transition}
                                    src={imgProjectcaera}
                                    alt="caera"
                                  ></motion.img>
                                </LazyLoad>
                              </Link>

                              <div
                                className="projektImage-shadow"
                                alt="caera"
                              ></div>
                            </div>
                          </Tween>
                        )}
                      </Scene>
                    </Controller>
                  </div>
                </Scene>
              </Controller>
            </div>
          </section>
        </Page>
      )}
    </MyContext.Consumer>
  );
};

export default Home;
