import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Footer from './Footer';
import ProjectFooter from './ProjectFooter';
import PageHero from './PageHero';
import Header from './Header';
import ProjectNav from './ProjectNav';
import { Controller, Scene } from "react-scrollmagic";
import { Tween, Timeline, SplitWords } from "react-gsap";
import {motion, AnimatePresence} from 'framer-motion';
import Link from "react-router-dom/Link";
import { MyContext } from './Context';
const transition = {duration:1, ease: [0.43, 0.13, 0.23, 0.96]};



import classNames from 'classnames';
const Cursor = () => {
    if (typeof navigator !== "undefined" && isMobile()) return null;

    const [position, setPosition] = React.useState({ x: 0, y: 0 });
    const [clicked, setClicked] = React.useState(false);
    const [linkHovered, setLinkHovered] = React.useState(false);
        const [draggableHovered, setDraggableHovered] = React.useState(false);
    const [hidden, setHidden] = React.useState(false);

    React.useEffect(() => {
        addEventListeners();
        handleLinkHoverEvents();
        return () => removeEventListeners();
    }, [window.location]);

    const addEventListeners = () => {
        document.body.addEventListener("mousemove", onMouseMove);
        document.body.addEventListener("mouseenter", onMouseEnter);
        document.body.addEventListener("mouseleave", onMouseLeave);
        document.body.addEventListener("mousedown", onMouseDown);
        document.body.addEventListener("mouseup", onMouseUp);
    };

    const removeEventListeners = () => {
        document.body.removeEventListener("mousemove", onMouseMove);
        document.body.removeEventListener("mouseenter", onMouseEnter);
        document.body.removeEventListener("mouseleave", onMouseLeave);
        document.body.removeEventListener("mousedown", onMouseDown);
        document.body.removeEventListener("mouseup", onMouseUp);
    };

    const onMouseMove = (e) => {
        setPosition({ x: e.clientX, y: e.clientY });
    };

    const onMouseDown = () => {
        setClicked(true);
    };

    const onMouseUp = () => {
        setClicked(false);
    };

    const onMouseLeave = () => {
        setHidden(true);
    };

    const onMouseEnter = () => {
        setHidden(false);
    };

    const handleLinkHoverEvents = () => {
      const timer = setTimeout(() => {
        document.querySelectorAll("a").forEach((el) => {
            el.addEventListener("mouseover", () => setLinkHovered(true));
            el.addEventListener("mouseout", () => setLinkHovered(false));
        });
        document.querySelectorAll(".angebot").forEach((el) => {
            el.addEventListener("mouseover", () => setLinkHovered(true));
            el.addEventListener("mouseout", () => setLinkHovered(false));
        });



            document.querySelectorAll(".react-draggable").forEach((el) => {
                el.addEventListener("mouseover", () => setDraggableHovered(true));
                el.addEventListener("mouseout", () => setDraggableHovered(false));
            });
    }, 1000);


    };

    const cursorClasses = classNames("cursor", {
        "cursor--clicked": clicked,
        "cursor--draggable-hovered": draggableHovered,
        "cursor--link-hovered": linkHovered
    });

    return (
        <div
            className={cursorClasses}
            style={{
              left: `${position.x}px`, top: `${position.y}px` }}
        ><span className="drag-icon">
        </span>
        </div>
    );
};
const isMobile = () => {
    const ua = navigator.userAgent;
    return /Android|Mobi/i.test(ua);
};

const Message = (message) => {
  const [showMessage, setShowMessage] = React.useState(true)
const onClick = () => setShowMessage(false)
let modalClasses = "modal-wrap";
var target = (new URLSearchParams(window.location.search)).get("target");
if(target === "grow" && showMessage){

 modalClasses = "modal-wrap -active";
}
if(target === "beta" && showMessage){

 modalClasses = "modal-wrap -active";
}
if(!showMessage){
 modalClasses = "modal-wrap -inactive";
}

if(target === "grow"){

        return(
          <motion.div
          initial={{opacity:1}}
          className={modalClasses}>
          <div onClick={onClick} className="modal-bg"></div>
          <div className="welcome">
          <a onClick={onClick}  className="btn-close">
          <svg viewPort="0 0 12 12" version="1.1"
xmlns="http://www.w3.org/2000/svg">
<line x1="1" y1="11"
  x2="11" y2="1"
  stroke="black"
  stroke-width="2"/>
<line x1="1" y1="1"
  x2="11" y2="11"
  stroke="black"
  stroke-width="2"/>
</svg>
          </a>

          <h3><strong>
          Hallo liebe Leute von der grow Werbeagentur! 👋 </strong></h3>
          <p>
          Schaut euch einfach mal auf meiner Webseite um.
          Wenn es schnell gehen soll, kann ich euch vorallem die Projekte <Link   to={{
              pathname: "/hyperactive",
              state: { trail: "fromClient" }
            }}> Hyperaktivität</Link> und <Link   to={{
                    pathname: "/Foodfriends",
                    state: { trail: "fromClient" }
                  }}> foodfriends</Link> empfehlen. Ansonsten viel Spaß beim durchklicken!</p>


          </div>

          </motion.div>
        )
}
if(target === "beta"){
  return(
    <motion.div
    initial={{opacity:1}}
    className={modalClasses}>
    <div onClick={onClick} className="modal-bg"></div>
    <div className="welcome">
    <a onClick={onClick}  className="btn-close">
    <svg viewPort="0 0 12 12" version="1.1"
xmlns="http://www.w3.org/2000/svg">
<line x1="1" y1="11"
x2="11" y2="1"
stroke="black"
stroke-width="2"/>
<line x1="1" y1="1"
x2="11" y2="11"
stroke="black"
stroke-width="2"/>
</svg>
    </a>

    <h3><strong>
    Moin 👋 </strong></h3>
    <p> Die Seite ist noch nicht ganz fertig, aber schau dich einfach mal um. Falls dir Fehler auffallen, bitte gerne bei mir melden! Vielen Dank.</p>


    </div>

    </motion.div>
  )

}
else{
  return(null)
}


    }

function Page({
  children,
  pageStyle,
  message,
  pageName,
  loaderText,
  pageType,
  pageHero,
  location: {
    state,
  },
}
) {


  const cx = classNames(
 'page',
   pageName,
   pageType,
   pageStyle,
   state ? state.trail : "",
  )

    var transitionVariantsExit;
    var initalTransition;
    var transitionVariantsEnter;

      var pageVariants;

if(state){
  initalTransition ={
    initial:{
      scale:0
    },
    animate: {
      scale: 0,
       transition: { ...transition }
    }
  }
  if(state.trail == "fromMenu" || state.trail == "fromLeistungen" || state.trail == "fromFooter" ){

    transitionVariantsEnter ={
    initial:{
      scale:.1
    },
    animate: {
      scale: 250,
       transition: { ...transition }
    }
    }

  }
  else{
        transitionVariantsExit = "";

    transitionVariantsEnter ="";
  }


}
else{
  initalTransition ={
    initial:{
      scale:1
    },
    animate: {
      scale: 1,
       transition: { ...transition }
    }
  }
}

  return (
    <MyContext.Consumer>
      {(context) => (
    <motion.div
    variants={pageVariants}
    initial="initial"
    animate="animate"
    exit="exit"
    id="page" className={cx}>



    <motion.div  variants={transitionVariantsEnter} className="pageTransition-enter">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1366 768" >
    <title>{"rect"}</title>
    <linearGradient
      id="prefix__a"
      gradientUnits="userSpaceOnUse"
      x1={179.01}
      y1={889.99}
      x2={1177.03}
      y2={-108.03}
      gradientTransform="matrix(1 0 0 -1 -2 768)"
    >
      <stop offset={0} stopColor="#647089" />
      <stop offset={0.89} stopColor="#647089" />
    </linearGradient>
    <path
      d="M0 0v768h1366V0H0zm683 391c-3.9 0-7-3.1-7-7s3.1-7 7-7 7 3.1 7 7-3.1 7-7 7z"
      fill="url(#prefix__a)"
    />
  </svg>
    </motion.div>


    <Message></Message>
    <div className="page-wrap">
    <div className="loadingIndicator">wwwwwwwww</div>

      <Cursor></Cursor>

      <Header context={context} activePageType={pageType} location={state} pageName={pageName}/>
        <PageHero context={context} location={state} pageTitle={pageHero} pageName={pageName}/>
      <div className="pageContent">
        {children}
      </div>
      <Footer context={context}>
      </Footer>
      </div>

    </motion.div>
  )}
</MyContext.Consumer>
  );
}

Page.propTypes = {
  children: PropTypes.node.isRequired,
  pageStyle: PropTypes.string,
};



export default withRouter(Page);
