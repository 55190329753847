import React from "react";
import Page from "./Page";
import Testemonials from "./Testemonials";
import phil from "./assets/img/phil.jpg";
import { RoughNotation, RoughNotationGroup } from "react-rough-notation";
import { Controller, Scene } from "react-scrollmagic";
import Hyphenated from "react-hyphen";
import de from "hyphenated-de";
import illuKonzeption from "./assets/img/illuKonzeption2.webm";
import illuKonzeptionMp4 from "./assets/img/illuKonzeption2.mp4";
import Link from "react-router-dom/Link";
import geld from "./assets/img/geld.png";
import rapidIcon from "./assets/img/rapid.svg";
import webdesignIcon from "./assets/img/webdesign.svg";
import brandingIcon from "./assets/img/branding.svg";
import { MyContext } from "./Context";
import ReactPlayer from "react-player";

import animations from "./assets/img/leistungen/Animations.png";
import creativeCoding from "./assets/img/leistungen/CreativeCoding.png";
import devFrontend from "./assets/img/leistungen/DevFrontend.png";
import interactionDesign from "./assets/img/leistungen/InteractionDesign.png";
import rapidPrototyping from "./assets/img/leistungen/RapidPrototyping.png";
import visualDesign from "./assets/img/leistungen/VisualDesign.png";

import { Tween } from "react-gsap";
var modalText = "";
var modalText2 = "";
var modalImage;
var brandingText =
  "Die Ent­wick­lung ei­nes Un­ter­neh­mens­auf­tritts ist eine sehr kom­ple­xe An­ge­le­gen­heit. Bei gro­ßen De­si­gnagen­tu­ren kön­nen hier Prei­se von bis zu 50.000€ ent­ste­hen. Die An­for­de­run­gen an ein gu­tes Bran­ding sind für den Lai­en oft nur schwer ein­zu­schät­zen. Der ers­te Ein­druck ei­nes De­signs muss na­tür­lich stim­men, den­noch funk­tio­nie­ren lei­der vie­le De­signs an­schlie­ßend in der An­wen­dung nur sehr schlecht. Das Logo muss auf vie­len Hin­ter­grün­den und ver­schie­de­nen Ska­lie­run­gen funk­tio­nie­ren. Ge­ra­de für jun­ge Un­ter­neh­men und Star­tups emp­feh­le ich zu Be­ginn auf ein Mi­ni­mal Via­ble De­sign zu set­zen, statt auf ei­nen Schlaf das PER­FEK­TE De­sign zu fin­den. Das dann spä­ter ohne grö­ße­ren AUf­wand er­wei­tert wer­den kann. Auch da­für ist ein mi­ni­ma­lis­ti­sches, ein­präg­sa­mes, kla­res De­sign wich­tig.";

var brandingText2 =
  "Dein Branding muss in enger Zusammenarbeit mit deinem Untenrehmen entstehen. Anders ist es nicht möglich. Das heißt, dass du dir für den Zeitraum des Projekts Zeit nimmst, um mit mir eng zusammen zu arbeiten und früh auf Designvorschläge zu reagieren. Im Gegenzug verzichte ich auf eine fixe Zahl von Korrekturschleifen. Nach dem von uns festgelegten Zeitraum (2-4 Wochen im Schnitt) sollte ein zufriedenstellendes Design entstanden sein. Bist du dennoch unzufrieden, können wir das Projekt gegen einen Aufpreis verlängern. Solltest du mir nicht vertrauen, und möchtest das Projekt abbrechen, erhälst du 50% des Preises zurück, kannst aber natürlich keine der entstanden Gestaltungen verwenden.";

var brandingTextImage = "./assets/img/brandingErklaerung.svg";

var appdesignText =
  "Spare Geld, indem wir deine Ideen als interaktiven Prototypen testen bevor es in die Entwicklung gehtUnd so gehts: wir besprechen unverbindlich dein Projekt un anschließend erstelle ich ein unverbindliches Angebot. Nach meiner Erfahrung hat sich das klassische 3 Korrekturschleifen Prinzip nicht bewährt. Ich lege wert auf einen sehr agilen Arbeitsprozess, in dem wir in einem engen Austausch Feedback sammeln und direkt umsetzen. Alle arbeiten werden direkt als interaktive Prototypen gestaltet, sodass die UX direkt getestet werden kann. Je nach Projektanforderung kann das Prototyping sehr simpel (Programme wie Invision), bis sehr komplex Framer) ausfallen. Sobald wir den LookFeel der App erarbeitet haben, werden alle notwendigen Screens ausgearbeitet. Um die Entwicklung später zu erleichtern werden alle Designelemente in einem Styleguide festgehalten.";

var webdevText =
  "Im Web­de­sign lege ich be­son­de­ren Wert auf eine in­di­vi­du­el­le, sehr mo­der­ne Ge­stal­tung, die dich von dei­nen Kon­kur­ren­ten ab­hebt. Nach­dem wir die Pro­jekt­an­for­de­run­gen be­spro­chen ha­ben, be­kommst du eine un­ver­bind­li­ches An­ge­bot, in de­nen die zu er­war­te­ten­den Kos­ten auf­ge­schlüs­selt sind. Ich kal­ku­lie­re im­mer erst mal mit dem Ide­al­zu­stand. Der be­ein­hal­tet ein kla­res Kon­zept für die Struk­tur der Web­sei­te, De­signs für un­ter­schied­li­che Tem­pla­tes, und di­ver­se Ani­ma­tio­nen, die für den Eye­cat­cher sor­gen. So­bald das De­sign ab­ge­stimmt ist, geht es an die Ent­wick­lung. Hier set­ze ich auf das Con­tent­ma­nag­ment Sys­tem Kir­by. Es er­laubt ein sehr in­di­vi­du­ell an­pass­ba­res Ba­ckend, das auf dei­ne Wün­sche zu­ge­schnit­ten wird. Die In­halts­pfle­ge ist ein­fach und schnell. Ein Hos­ting bie­te ich nicht an, ver­wei­se aber ger­ne auf ge­eig­ne­te An­bie­ter und küm­me­re mich auch an­schlie­ßend um den Li­ve­gang der fer­ti­gen Web­sei­te. Soll­te es sich um eine Web­sei­te han­deln, die re­gel­mä­ßi­ge Pfle­ge er­for­dert, bie­te ich dir auch ger­ne Pfle­ge­ver­trä­ge an über ein fes­tes Stun­den­kon­tin­gent.";
import LazyLoad from "react-lazyload";

const Modal = ({ isOpen, closeModal, target }) => {
  if (isOpen) {
    if (target == "branding") {
      modalText = brandingText;
      modalText2 = brandingText2;
      modalImage = brandingTextImage;
    }
    if (target == "webdev") {
      modalText = webdevText;
    }
    if (target == "appdesign") {
      modalText = appdesignText;
    }

    return (
      <div className="modal -active">
        <div className="modal-container">
          <a onClick={closeModal} className="btn-close">
            <svg
              viewPort="0 0 12 12"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line
                x1="1"
                y1="11"
                x2="11"
                y2="1"
                stroke="black"
                stroke-width="2"
              />
              <line
                x1="1"
                y1="1"
                x2="11"
                y2="11"
                stroke="black"
                stroke-width="2"
              />
            </svg>
          </a>
          <p className="p-mini">
            <Hyphenated>{modalText}</Hyphenated>
          </p>
          <img src={modalImage} />
          <p className="p-mini">
            <Hyphenated>{modalText2}</Hyphenated>
          </p>
        </div>
      </div>
    );
  } else {
    return <React.Fragment></React.Fragment>;
  }
};

class Leistungen extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      height: 0,
      height0: 0,
      height1: 0,
      height2: 0,
      height3: 0,
      height4: 0,
    };

    this.heights = this.heights.bind(this);
    this.calcHeights = this.calcHeights.bind(this);
  }
  calcHeights() {
    setTimeout(() => {
      let height = document.getElementById("e").clientHeight;
      let height0 =
        document.getElementById("e0").clientHeight -
        document.getElementById("e").scrollHeight;
      let height1 = document.getElementById("konzeption").clientHeight;
      let height2 = document.getElementById("design").clientHeight;
      let height3 = document.getElementById("entwicklung").clientHeight;

      this.setState({ height, height0, height1, height2 });
    }, 500);
  }

  heights() {
    let height = this.divElement.clientHeight;
    let height0 = this.divElement0.clientHeight - this.divElement.scrollHeight;
    let height1 = this.divElement1.scrollHeight;
    let height2 = this.divElement2.scrollHeight;
    let height3 = this.divElement3.scrollHeight;

    this.setState({ height, height0, height1, height2 });
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.heights();
    window.addEventListener("resize", this.calcHeights);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.calcHeights);
  }

  render() {
    return (
      <MyContext.Consumer>
        {(context) => (
          <Page
            pageName="Leistungen"
            pageType="Leistungen"
            loaderText="Leistungen"
            pageHero="Digitale Produkte, die Spaß machen."
            pageStyle="bright"
          >
            <section className="leistungen-detail -displayDesktop">
              <div className="grid wrap">
                {" "}
                <div className="trigger" id="trigger0"></div>
                <div className="col-35_sm-100 -displayDesktop">
                  <Controller>
                    <Scene duration={this.state.height0} triggerHook={0.16} pin>
                      <ul
                        id="e"
                        ref={(divElement) => (this.divElement = divElement)}
                        className="scroll-menu"
                      >
                        <Controller>
                          <Scene
                            triggerHook={0.4}
                            duration={this.state.height1}
                            classToggle="active"
                            triggerElement="#konzeption"
                          >
                            <li>
                              <a>
                                {
                                  context.state.translation.default.services
                                    .what
                                }
                              </a>
                            </li>
                          </Scene>
                          <Scene
                            triggerHook={0.4}
                            duration={this.state.height2}
                            classToggle="active"
                            triggerElement="#design"
                          >
                            <li>
                              <a>
                                {context.state.translation.default.services.why}
                              </a>
                            </li>
                          </Scene>
                          <Scene
                            triggerHook={0.4}
                            duration={this.state.height3}
                            classToggle="active"
                            triggerElement="#entwicklung"
                          >
                            <li>
                              <a>
                                {
                                  context.state.translation.default.services
                                    .because
                                }
                              </a>
                            </li>
                          </Scene>
                        </Controller>
                      </ul>
                    </Scene>
                  </Controller>
                </div>
                <div className=" col-65 col_sm-100 textblock">
                  <ul
                    id="e0"
                    ref={(divElement0) => (this.divElement0 = divElement0)}
                    className="scrollingTextBlock"
                  >
                    <Controller>
                      <Scene
                        triggerHook={0.4}
                        duration={this.state.height1}
                        classToggle="active"
                        triggerElement="#konzeption"
                      >
                        <li
                          id="konzeption"
                          ref={(divElement1) =>
                            (this.divElement1 = divElement1)
                          }
                        >
                          {
                            //  <p> Mit den neuesten Technologien ausgestattet, biete ich
                            // eine robuste Suite von digitalen Dienstleistungen,
                            // einschließlich innovativem Web- und Appdesign, Branding,
                            // Rapid Prototyping und Webdevelopment.
                            //
                            // Egal ob du ein bereits etabliertes Unternehmen bist,
                            // oder gerade noch in der Gründungsphase steckst: mit
                            // meiner jahrelangen Erfahrung im digitalen Bereich, bin
                            // ich der passende Ansprechpartner für jedes Unternehmen.  </p>
                          }
                          <h2>
                            {" "}
                            {context.state.translation.default.services.what}
                          </h2>{" "}
                          <Hyphenated language={de}>
                            <h3 style={{ fontWeight: "400" }}>
                              {
                                context.state.translation.default.services
                                  .whatText
                              }
                            </h3>
                          </Hyphenated>
                          <span>
                            {" "}
                            {
                              context.state.translation.default.services
                                .whatTextSubline
                            }
                          </span>
                        </li>
                      </Scene>

                      <Scene
                        triggerHook={0.4}
                        duration={this.state.height2}
                        classToggle="active"
                        triggerElement="#design"
                      >
                        <li
                          id="design"
                          ref={(divElement2) =>
                            (this.divElement2 = divElement2)
                          }
                        >
                          {
                            //       <p>
                            // Warum du mich und keine große Agentur beauftragen solltest?
                            //         Weil ich meine Arbeit liebe und in jedes Projekt 100% Energie, Aufmerksamkeit und Kreativität stecke um das best mögliche Ergebnis zu erreichen. Dein Projekt steht für eine Zeit im Mittelpunkt meines Lebens.
                            //          Ich bin dein unmittelbarer Ansprechbar und erarbeite das Projekt in enger Zusammenarbeit mit dir genau so wie du es haben möchtest.
                            //       </p>
                          }
                          <h2>
                            {" "}
                            {context.state.translation.default.services.why}
                          </h2>
                          <h3 style={{ fontWeight: "400" }}>
                            {" "}
                            <Hyphenated language={de}>
                              {
                                context.state.translation.default.services
                                  .whyText
                              }{" "}
                            </Hyphenated>
                          </h3>
                          <span>
                            {
                              context.state.translation.default.services
                                .whyTextSubline
                            }
                          </span>
                        </li>
                      </Scene>

                      <Scene
                        triggerHook={0.4}
                        duration={this.state.height3}
                        classToggle="active"
                        triggerElement="#entwicklung"
                      >
                        <li
                          id="entwicklung"
                          ref={(divElement3) =>
                            (this.divElement3 = divElement3)
                          }
                        >
                          {
                            // <p>
                            //   Mein Ziel ist es, dauerhafte Beziehungen zwischen meinen
                            //   Kunden und ihrem Publikum aufzubauen: Mit ästhetisch hochwertigen,
                            //   inutitiven und innovativen digitale Erlebnissen.
                            // </p>
                          }{" "}
                          <h2>
                            {context.state.translation.default.services.because}
                          </h2>
                          <h3 style={{ fontWeight: "400" }}>
                            <Hyphenated>
                              {
                                context.state.translation.default.services
                                  .becauseText
                              }
                            </Hyphenated>
                          </h3>
                          <span>
                            {
                              context.state.translation.default.services
                                .becauseTextSubline
                            }
                          </span>
                        </li>
                      </Scene>
                    </Controller>
                  </ul>
                </div>
              </div>
            </section>

            <section className="leistungen-detail -displayMobile">
              <div className="grid wrap">
                <div className=" col-65 col_sm-100 textblock">
                  <ul className="scrollingTextBlock">
                    <li id="konzeption" className="active">
                      <h2>Was?</h2>
                      <h3 style={{ fontWeight: "400" }}>
                        {context.state.translation.default.services.whatText}
                      </h3>
                      <span>
                        {" "}
                        {
                          context.state.translation.default.services
                            .whatTextSubline
                        }
                      </span>
                    </li>

                    <li id="design" className="active">
                      <h2>Warum?</h2>
                      <h3 style={{ fontWeight: "400" }}>
                        {context.state.translation.default.services.whyText}
                      </h3>
                      <span>
                        {" "}
                        {
                          context.state.translation.default.services
                            .whyTextSubline
                        }
                      </span>
                    </li>

                    <li id="entwicklung" className="active">
                      <h2>Darum!</h2>
                      <h3 style={{ fontWeight: "400" }}>
                        {context.state.translation.default.services.becauseText}
                      </h3>
                      <span>
                        {" "}
                        {
                          context.state.translation.default.services
                            .becauseTextSubline
                        }
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </section>

            <section>
              <div className="info-section grid-noGutter wrap-wide">
                <div className="col-50_sm-100">
                  <div className="infobox-1 infobox-b">
                    <div className="infobox-wrap">
                      <i>⭐</i>
                      <Hyphenated language={de}>
                        {context.state.translation.default.services.value1}
                      </Hyphenated>
                    </div>
                  </div>
                </div>
                <div className="col-50_sm-100">
                  <div className="infobox-2 infobox-b">
                    <div className="infobox-wrap">
                      <i>🎁</i>
                      <Hyphenated language={de}>
                        {context.state.translation.default.services.value2}
                      </Hyphenated>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="LeistungenOverview">
              <div className="wrap">
                <div className="grid-noGutter-center">
                  <div className="col_xs-80 col_sm-50 col-33 leistung-box">
                    <div>
                      <p>Rapid Prototyping</p>
                      <img src={rapidPrototyping} />
                    </div>
                  </div>
                  <div className="col_xs-80 col_sm-50 col-33 leistung-box">
                    <div>
                      <p>Visual Design</p>
                      <img src={visualDesign} />
                    </div>
                  </div>
                  <div className="col_xs-80 col_sm-50 col-33 leistung-box">
                    <div>
                      <p>Interaction Design</p>
                      <img src={interactionDesign} />
                    </div>
                  </div>
                  <div className="col_xs-80 col_sm-50 col-33 leistung-box">
                    <div>
                      <p> Animations</p>
                      <img src={animations} />
                    </div>
                  </div>
                  <div className="col_xs-80 col_sm-50 col-33 leistung-box">
                    <div>
                      <p>
                        Frontend & <br /> Creative Coding
                      </p>
                      <img src={devFrontend} />
                    </div>
                  </div>
                  <div className="col_xs-80 col_sm-50 col-33 leistung-box">
                    <div>
                      <p>Art Direction</p>
                      <img src={creativeCoding} />
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="work-teaser">
              <div className="wrap grid">
                <div className="col-60_sm-100 ">
                  <h2>
                    {context.state.translation.default.services.workTeaserh2}
                  </h2>
                  <p>
                    {context.state.translation.default.services.workTeaserP}
                  </p>

                  <Link
                    to={{
                      pathname: "/works",
                      state: { trail: "fromLeistungen" },
                    }}
                    className={"btn btn-primary"}
                  >
                    {
                      context.state.translation.default.services
                        .workTeaserButton
                    }{" "}
                    <i>
                      <svg
                        stroke="rgba(255,255,255,0.6)"
                        strokeWidth="0.5"
                        fill="rgba(255,255,255,0.6)"
                        width="26px"
                        height="17px"
                        id="Ebene_1"
                        data-name="Ebene 1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 21.43 15.28"
                      >
                        <title>iconmonstr-arrow-right-thin</title>
                        <path
                          d="M12.26,19.14,11.32,18l7-5.79H0V10.77H18.31L11.31,5l.95-1.12,9.17,7.62Z"
                          transform="translate(0 -3.86)"
                        />
                      </svg>
                      <svg
                        stroke="rgba(255,255,255,0.6)"
                        strokeWidth="0.5"
                        fill="rgba(255,255,255,0.6)"
                        width="26px"
                        height="17px"
                        id="Ebene_1"
                        data-name="Ebene 1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 21.43 15.28"
                      >
                        <title>iconmonstr-arrow-right-thin</title>
                        <path
                          d="M12.26,19.14,11.32,18l7-5.79H0V10.77H18.31L11.31,5l.95-1.12,9.17,7.62Z"
                          transform="translate(0 -3.86)"
                        />
                      </svg>
                    </i>
                  </Link>

                  {
                    // <Link
                    //   to={{
                    //     pathname: '/VisualImpression',
                    //   }}
                    //   className={"btn btn-primary -grey"}
                    // >
                    //     <i>
                    //
                    //                      <svg   stroke="rgba(255,255,255,0.6)" strokeWidth="0.5"  fill="rgba(255,255,255,0.6)"width="26px"  height="17px" id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.43 15.28"><title>iconmonstr-arrow-right-thin</title><path d="M12.26,19.14,11.32,18l7-5.79H0V10.77H18.31L11.31,5l.95-1.12,9.17,7.62Z" transform="translate(0 -3.86)"/></svg>
                    //                      <svg   stroke="rgba(255,255,255,0.6)" strokeWidth="0.5"  fill="rgba(255,255,255,0.6)"width="26px"  height="17px" id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.43 15.28"><title>iconmonstr-arrow-right-thin</title><path d="M12.26,19.14,11.32,18l7-5.79H0V10.77H18.31L11.31,5l.95-1.12,9.17,7.62Z" transform="translate(0 -3.86)"/></svg>
                    //       </i>
                    //   Experimente</Link>
                  }
                </div>
                <div className="col-33_sm-100">
                  <div className="unwrapVideo-wrap">
                    <LazyLoad height={200} offset={1400}>
                      <ReactPlayer
                        playing={true}
                        playsinline={true}
                        muted={true}
                        width="100%"
                        height="auto"
                        url={[illuKonzeption, illuKonzeptionMp4]}
                      />
                    </LazyLoad>
                  </div>
                </div>
              </div>
            </section>
            {
              //                       <section id="dienstleistungen"  className="angebote">
              //
              //                         <div className=" grid wrap ">
              //                        <a className={this.state.activeApp ? "col-100_md-100 angebot -app -active" : "col-100_md-100 angebot -app "} >
              //                        <Link
              //                          to={{
              //                            pathname: "/Appdesign"
              //                          }}
              //                          className="link-wrap"
              //                        >
              //                              <div className="grid">
              //                               <div className=" textblock">
              //                               <i className="icon-more"><span></span><span></span></i>
              //                               <img className="icon-small icon-rapid" src={rapidIcon}/>
              //
              //
              //                         <h3>App Design & Prototyping </h3>
              //
              //  <a className="btn-primary">Mehr erfahren </a>
              //                           </div>
              //                          </div>
              //                          </Link>
              //                        </a>
              //                           <a className={this.state.activeDev ? "col-100_md-100 angebot -webdev -active" : "col-100_md-100  angebot -webdev "}>
              //                           <Link
              //                             to={{
              //                               pathname: "/Webdev"
              //                             }}
              //                             className="link-wrap"
              //                           >
              //                                 <div className="grid ">
              //                                  <div className=" textblock">
              //                                        <i className="icon-more"><span></span><span></span></i>
              //                                    <img className="icon-small icon-webdesign" src={webdesignIcon}/>
              //                            <h3>Webdesign und Entwicklung </h3>
              //
              //                      <a className="btn-primary">Mehr erfahren </a>
              //
              //                              </div>
              //                             </div>
              //                             </Link>
              //                           </a>
              //
              //                               <a className={this.state.activeBrand ? "col-100_md-100   angebot -branding -active" : "col-100_md-100  angebot -branding "} >
              //                               <Link
              //                                 to={{
              //                                   pathname: "/Branding"
              //                                 }}
              //                                 className="link-wrap"
              //                               >
              //                                   <div className="grid ">
              //                                    <div className="  textblock">
              //                                          <i className="icon-more"><span></span><span></span></i>
              //                                        <img className="icon-small icon-branding" src={brandingIcon}/>
              //                              <h3>Unternehmensauftritte</h3>
              //
              //                                      <a className="btn-primary">Mehr erfahren </a>
              //                                </div>
              //                               </div>
              //                               </Link>
              //                             </a>
              // </div>
              //
              //                       </section>
            }

            {
              // <section>
              //   <div className="wrap">
              //     <div className="grid">
              //     <div className="col-100_md-100 faqsection-header">
              //   <h3>Fragen über Fragen</h3>
              //     </div>
              //     </div>
              //
              //     <div className="grid -spaceBetween">
              //
              //         <div className="col-100">
              //      <div className={this.state.activeFAQ1 ? " -active faq" : " faq "}  onClick={ () => this.setState({
              //      activeFAQ1: !this.state.activeFAQ1,
              //      activeFAQ2: false
              //      }) }>
              //
              //       <div className="flex faq-header">
              //           <h4>Was kostest du?</h4>
              //                        <svg
              //                 xmlns="http://www.w3.org/2000/svg"
              //                 x="0"
              //                 y="0"
              //                 enableBackground="new 0 0 573.3 495"
              //                 version="1.1"
              //                 fill="rgb(120, 132, 157)"
              //                 viewBox="0 0 573.3 495"
              //                 xmlSpace="preserve"
              //               >
              //                 <path
              //                   d="M265.4 384.9L49.9 169.4c-11.7-11.7-11.7-30.7 0-42.4s30.7-11.7 42.4 0l194.3 194.3L481 126.9c11.7-11.7 30.7-11.7 42.4 0s11.7 30.7 0 42.4L307.9 384.9c-11.8 11.7-30.8 11.7-42.5 0z"
              //                   className="st1"
              //                 ></path>
              //               </svg>
              //              <svg
              //       xmlns="http://www.w3.org/2000/svg"
              //       x="0"
              //       y="0"
              //       enableBackground="new 0 0 573.3 495"
              //       version="1.1"
              //       fill="rgb(120, 132, 157)"
              //       viewBox="0 0 573.3 495"
              //       xmlSpace="preserve"
              //     >
              //       <path
              //         d="M265.4 384.9L49.9 169.4c-11.7-11.7-11.7-30.7 0-42.4s30.7-11.7 42.4 0l194.3 194.3L481 126.9c11.7-11.7 30.7-11.7 42.4 0s11.7 30.7 0 42.4L307.9 384.9c-11.8 11.7-30.8 11.7-42.5 0z"
              //         className="st1"
              //       ></path>
              //     </svg>
              //       </div>
              //
              //                <div className="faq-wrap">
              //                <div className="grid -noPadding">
              //                <div className="col_xs-100 col" >
              // <p className="p-mini">
              // Preise als Kreativer festzulegen ist nicht so einfach. In die Kalkulation eines Projekts fließen viele Variablen ein, die ich erst nach einem ersten Gespräch mit dir einschätzen kann.
              // Wie hoch ist der Aufwand? Wie innovativ ist das Projekt? Wie wichtig ist Geschwindigkeit? Wie viel Kommunikation wird notwendig sein?
              // </p>
              // <p className="-strong p-mini f-blue">
              // Kurz gesagt: Ich koste was ich dir nachhaltig an Wert generieren werde!
              // </p>
              //                </div>
              //                      <div className="col_xs-100 col" >
              //     <img className="img" style={{width:'100%'}} src={geld}/>
              //                  </div>
              //                </div>
              //
              //
              //
              //
              //
              //     <div className="grid">
              //
              //     <div className="col-50">
              // {
              //       // <blockquote>
              //       //   Gutes Design wird dir Probleme lösen, die dir noch gar nicht bewusst sind.
              //       //   </blockquote>
              // }
              //
              //     </div>
              //
              //     </div>
              //     </div><hr/>
              //         </div>
              //
              //
              //
              //         </div>
              //
              //
              //
              //     </div>
              //   </div>
              // </section>
            }
            <Testemonials context={context}></Testemonials>
            {
              //   <p>
              // Ich bin immer bestrebt 100% zu geben, dafür schaffe ich mit meinen Kunden ein nachhaltiges, gutes Arbeitsumfeld.
              // Dazu gehören _wirklich_ faire Preise  eine offene, transparente, freundliche Kommunikation und eine strukturierte Projektplanung mit klar definierten Milestones.
              // </p>
              //   <p>
              // Tldr; Ich bin ein sehr effizienter und schneller Gestalter. Spezialisiert auf MVPs. Ich kalkuliere auf Basis des Projekt Briefings.
              //  Ansonsten bin ich für 90€/h auch auf "frei Schnautze" buchbar.
              //
              // </p>
            }
          </Page>
        )}
      </MyContext.Consumer>
    );
  }
}

export default Leistungen;
