import React from "react";
import { HashLink as Link } from "react-router-hash-link";
import PropTypes from "prop-types";
import MenuModal from "./MenuModal";
import imgLogo from "./assets/img/logo.svg";
import SmoothScrolling from "./smoothScrolling";
import { Controller, Scene } from "react-scrollmagic";
import { Tween } from "react-gsap";
import ReactDOM from "react-dom";
var oldWidth = 0;

const Branding = ({ brandingClass, myFunction }) => {
  var canClick = false;

  if (myFunction === "scrollUp") {
    canClick = true;
  }

  return (
    <Link
      to={{ pathname: "/", state: { trail: "fromMenu" } }}
      data-status="unfolded"
      className={brandingClass}
    >
      <div className="branding-wrap">
        einfach<span className="f-blue">.</span>
      </div>
    </Link>
  );
};

function scrollUp() {
  SmoothScrolling.scrollTo("top");
}

const ProjectNav = ({ nextProject, prevProject }) => {
  var nextProjectClass;
  var prevProjectClass;

  if (prevProject == "") {
    prevProjectClass = "-inactive";
  } else {
  }

  if (nextProject == "") {
    nextProjectClass = "-inactive";
  } else {
  }

  return (
    <div className="projectNav-mini">
      <Link
        className={prevProjectClass}
        to={{
          pathname: "/" + prevProject,
          state: { trail: "prevProject" },
        }}
      >
        {" "}
        <svg
          cxmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
          viewBox="3.6 4.6 16 16"
          fill="none"
          stroke="rgba(11, 11, 13, 1)"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
          x="0px"
          y="0px"
        >
          <polyline points="9 6 18 6 18 15" />
        </svg>
      </Link>
      <Link
        className={nextProjectClass}
        to={{
          pathname: "/" + nextProject,
          state: { trail: "nextProject" },
        }}
      >
        {" "}
        <svg
          cxmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
          viewBox="3.6 4.6 16 16"
          fill="none"
          stroke="rgba(11, 11, 13, 1)"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
          x="0px"
          y="0px"
        >
          <polyline points="9 6 18 6 18 15" />
        </svg>
      </Link>
    </div>
  );
};
class ScrollUpLink extends React.Component {
  render() {
    return (
      <a onClick={scrollUp} className={this.props.backLinkClass}>
        <svg
          cxmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
          viewBox="3.6 4.6 16 16"
          fill="none"
          stroke="rgba(11, 11, 13, 1)"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
          x="0px"
          y="0px"
        >
          <line x1="6" y1="18" x2="18" y2="6" />
          <polyline points="9 6 18 6 18 15" />
        </svg>
        <svg
          cxmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
          viewBox="3.6 4.6 16 16"
          fill="none"
          stroke="rgba(11, 11, 13, 1)"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
          x="0px"
          y="0px"
        >
          <line x1="6" y1="18" x2="18" y2="6" />
          <polyline points="9 6 18 6 18 15" />
        </svg>
      </a>
    );
  }
}

const BackLink = ({ backLinkClass, url, activePageType, location }) => {
  if (activePageType == "Leistungen") {
    return (
      <Link
        to={{
          pathname: "/",
          state: { trail: "fromLeistungenToHome" },
        }}
        className={backLinkClass}
      >
        <svg
          cxmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
          viewBox="3.6 4.6 16 16"
          fill="none"
          stroke="rgba(11, 11, 13, 1)"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
          x="0px"
          y="0px"
        >
          <line x1="6" y1="18" x2="18" y2="6" />
          <polyline points="9 6 18 6 18 15" />
        </svg>
        <svg
          cxmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
          viewBox="3.6 4.6 16 16"
          fill="none"
          stroke="rgba(11, 11, 13, 1)"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
          x="0px"
          y="0px"
        >
          <line x1="6" y1="18" x2="18" y2="6" />
          <polyline points="9 6 18 6 18 15" />
        </svg>
      </Link>
    );
  }
  if (activePageType == "Dienstleistung") {
    return (
      <Link to="/Leistungen#dienstleistungen" className={backLinkClass}>
        <svg
          cxmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
          viewBox="3.6 4.6 16 16"
          fill="none"
          stroke="rgba(11, 11, 13, 1)"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
          x="0px"
          y="0px"
        >
          <line x1="6" y1="18" x2="18" y2="6" />
          <polyline points="9 6 18 6 18 15" />
        </svg>
        <svg
          cxmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
          viewBox="3.6 4.6 16 16"
          fill="none"
          stroke="rgba(11, 11, 13, 1)"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
          x="0px"
          y="0px"
        >
          <line x1="6" y1="18" x2="18" y2="6" />
          <polyline points="9 6 18 6 18 15" />
        </svg>
      </Link>
    );
  }
};

class ScrollIndicator extends React.Component {
  render() {
    return (
      <Tween
        from={{ scaleX: 0 }}
        to={{ scaleX: this.props.scrollPosition }}
        duration="0"
        ease="easeOut"
      >
        <div className="scollindicator"></div>
      </Tween>
    );
  }
}

const headerHeight = () => {
  const doc = document.documentElement;
  const header = document.getElementById("header");

  if (window.innerWidth != oldWidth)
    doc.style.setProperty("--header-height", `${header.clientHeight}px`);
  oldWidth = window.innerWidth;
};

window.addEventListener("resize", headerHeight);

class Header extends React.Component {
  componentDidMount() {
    headerHeight();
    window.addEventListener("scroll", this.listenToScroll);
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.listenToScroll);
  }

  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      bottomReached: false,
      windowHeight: document.documentElement.clientHeight,
      bottomOffsetReached: false,
    };
    this.listenToScroll = this.listenToScroll.bind(this);
  }

  listenToScroll = () => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;

    const windowHeight = document.documentElement.clientHeight;
    const scrolled = winScroll / height;
    const scrolledOffset = winScroll / (height - windowHeight / 3);
    const scrollAmount =
      document.body.scrollTop || document.documentElement.scrollTop;

    const footerHeight = this.props.footerHeight;

    if (scrolled >= 0.9) {
      this.setState({ bottomReached: true });
    } else {
      this.setState({ bottomReached: false });
    }
    this.setState({
      scrollPosition: scrolled,
      scrollAmount: scrollAmount,
      windowHeight: windowHeight,
      winScroll: winScroll,
      intervalId: 0,
    });
  };

  render() {
    var activePage = this.props.pageName;
    var headerClass = activePage + "Active" + " header";
    let mainLink;
    var projectNav;

    var brandingClass;
    var backLinkClass;
    var navClasses = "";
    var url;
    var navStyles;
    var modalHandleButtonStyle;

    if (this.props.activePageType === "Project") {
      if (this.props.pageName === "Hyperaktivität") {
        projectNav = (
          <ProjectNav nextProject="foodfriends" prevProject={""}></ProjectNav>
        );
      }

      if (this.props.pageName === "foodfriends") {
        projectNav = (
          <ProjectNav
            nextProject="autarq"
            prevProject={"Hyperactive"}
          ></ProjectNav>
        );
      }

      if (this.props.pageName === "Autarq") {
        projectNav = (
          <ProjectNav
            nextProject="caera"
            prevProject={"foodfriends"}
          ></ProjectNav>
        );
      }

      if (this.props.pageName === "caera") {
        projectNav = (
          <ProjectNav
            nextProject="Pfotenphysio"
            prevProject={"Autarq"}
          ></ProjectNav>
        );
      }
      if (this.props.pageName === "Pfotenphysio") {
        projectNav = (
          <ProjectNav
            nextProject="HammerUndPartner"
            prevProject={"caera"}
          ></ProjectNav>
        );
      }

      if (this.props.pageName === "HammerUndPartner") {
        projectNav = (
          <ProjectNav nextProject="" prevProject={"Pfotenphysio"}></ProjectNav>
        );
      }
    }

    {
      //  if (this.props.activePageType === "Project" ||  this.props.activePageType === "About"  || activePage === "Projektanfrage" || activePage === "VisualImpression" || activePage === "Projekte" || activePage === "Impressum" || activePage ==="Kontakt"
      //)
      //{
      //    if(this.state.scrollPosition >= 0.02){
      //      brandingClass = "branding brandingScrolled";
      //     url = "#top";
      //     backLinkClass = `scrollUpLink link`;
      //        mainLink = (
      //          <ScrollUpLink url={url} backLinkClass={backLinkClass}>
      //        </ScrollUpLink>
      //    );
      //  }
      //  else{
      //   brandingClass = "branding";
      //   backLinkClass = `backlink link`;
      //    mainLink = (
      //        <BackLink  backLinkClass={backLinkClass} location={this.props.location} activePageType={this.props.activePageType}>
      //        </BackLink>
      //      );
      //    }
      //   headerClass = 'header ' + 'subPage ' + activePage + 'Active';
      //  }
    }

    if (this.state.scrollPosition >= 0.02) {
      if (!this.state.bottomReached && !this.state.isOpen) {
        brandingClass = "branding brandingScrolled ";
      } else {
        brandingClass = "branding brandingAbgestoßen";
      }
    }

    // if(activePage === "Home" || activePage ==="error" ) {

    if (this.state.scrollPosition >= 0.02) {
      if (this.state.bottomOffsetReached) {
        brandingClass = "branding ";
      }

      mainLink = (
        <Branding
          myFunction="scrollUp"
          brandingClass={brandingClass}
        ></Branding>
      );
    } else {
      brandingClass = "branding brandingAbgestoßen";
      mainLink = (
        <Branding myFunction="/" brandingClass={brandingClass}></Branding>
      );
    }
    // }

    if (
      activePage === "Leistungen" ||
      this.props.activePageType === "Dienstleistung"
    ) {
      if (
        this.state.scrollAmount <= this.state.windowHeight ||
        this.state.scrollAmount === undefined
      ) {
        if (this.state.scrollPosition >= 0.01) {
          backLinkClass = `scrollUpLink link -grey`;

          mainLink = (
            <ScrollUpLink
              url={url}
              backLinkClass={backLinkClass}
            ></ScrollUpLink>
          );
        } else {
          backLinkClass = `backlink link -grey`;
          mainLink = (
            <BackLink
              backLinkClass={backLinkClass}
              location={this.props.location}
              activePageType={this.props.activePageType}
            ></BackLink>
          );
        }
      } else {
        backLinkClass = `scrollUpLink link `;
        mainLink = (
          <ScrollUpLink url={url} backLinkClass={backLinkClass}></ScrollUpLink>
        );
      }
    }

    if (this.props.activePageType === "Project") {
      modalHandleButtonStyle = "-hidden";
    } else {
      if (!this.state.isOpen) {
        if (
          this.state.scrollAmount <= this.state.windowHeight ||
          this.state.scrollAmount === undefined
        ) {
          modalHandleButtonStyle = "btn btn-tertiary btn-burger -open -grey";
        } else {
          modalHandleButtonStyle = "btn btn-tertiary btn-burger -open";
        }
      } else {
        modalHandleButtonStyle = "btn btn-tertiary btn-burger -close";
      }
    }

    return (
      <React.Fragment>
        <header id="header" className={headerClass}>
          <MenuModal
            context={this.props.context}
            isOpen={this.state.isOpen}
            activePage={this.props.pageName}
          >
            {" "}
          </MenuModal>

          <a
            onClick={(e) => this.setState({ isOpen: !this.state.isOpen })}
            className="menuinfos-bg"
          ></a>
          <ScrollIndicator
            scrollPosition={this.state.scrollPosition}
          ></ScrollIndicator>
          {mainLink}

          <nav style={navStyles} className={navClasses}>
            <a
              onClick={(e) => this.setState({ isOpen: !this.state.isOpen })}
              className={modalHandleButtonStyle}
            >
              <span className="btn-text_opened btn-text">
                <span className="burger-line"></span>
                <span className="burger-line"></span>
                <span className="burger-line"></span>
                <span className="burger-line"></span>
              </span>
              <span className="btn-text_closed btn-text">
                <span className="burger-line"></span>
                <span className="burger-line"></span>
                <span className="burger-line"></span>
                <span className="burger-line"></span>
              </span>
            </a>
            {projectNav}
          </nav>
        </header>
      </React.Fragment>
    );
  }
}

Header.propTypes = {
  pageName: PropTypes.string,
  location: PropTypes.string,
  activePageType: PropTypes.string,
};

export default Header;
