import React from "react";
import Page from "./Page";
import illuEntwicklung from "./assets/img/illuEntwicklung.svg";
import illuDesign from "./assets/img/illuDesign.svg";
import illuMe from "./assets/img/miniportrait.jpg";
import { Controller, Scene } from "react-scrollmagic";
import Link from "react-router-dom/Link";
import axios from "axios";
const API_PATH = "https://einfach.es/beta/mail/index.php";
import { motion } from "framer-motion";
const transition = { duration: 0.3, ease: [0.6, 0.01, 0.4, 0.9] };
import { MyContext } from "./Context";

import { ScrollToTop } from "react";
function handleClick(e) {}
class Projektanfrage extends React.Component {
  constructor(props) {
    super(props);

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.closeSuccess = this.closeSuccess.bind(this);

    this.state = { width: 0, height: 0 };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({
      processStarted: true,
    });
    axios({
      method: "post",
      url: `${API_PATH}`,
      data: this.state,
    })
      .then((result) => {
        this.setState({
          mailSent: true,
          processStarted: false,
        });
      })
      .catch((error) => this.setState({ error: error.message }));
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
      mailSent: false,
      error: null,
    });
  }

  closeSuccess() {
    this.setState({
      mailSent: false,
    });
  }

  updateWindowDimensions() {
    this.setState({
      width: window.innerWidth,
      height: document.getElementById("projektanfrage-hero").clientHeight,
    });
  }
  render() {
    return (
      <MyContext.Consumer>
        {(context) => (
          <Page
            pageName="Projektanfrage"
            loaderText="✌️ Projektanfrage"
            pageHero="Projektanfrage"
            pageStyle=""
          >
            <Controller>
              <Scene duration={this.state.height} triggerHook={0.16} pin>
                <section className="form-section">
                  <div className="wrap grid">
                    <div className="form col-100_sm-100">
                      <form onSubmit={this.handleSubmit}>
                        <div className="field field-area">
                          <h2>
                            {" "}
                            {
                              context.state.translation.default.projectrequest
                                .h2
                            }
                          </h2>
                          <div className="checkbox_grp">
                            <a className="checkButton">
                              <input
                                onChange={this.handleInputChange}
                                name="branding"
                                type="checkbox"
                              />
                              <label>Branding</label>
                            </a>
                            <a className="checkButton">
                              <input
                                onChange={this.handleInputChange}
                                name="webdesign"
                                type="checkbox"
                              />
                              <label>Web Design</label>
                            </a>
                            <a className="checkButton">
                              <input type="checkbox" />
                              <label>App Design</label>
                            </a>
                            <a className="checkButton">
                              <input
                                onChange={this.handleInputChange}
                                name="print"
                                type="checkbox"
                              />
                              <label>Print Design</label>
                            </a>
                            <a className="checkButton">
                              <input
                                onChange={this.handleInputChange}
                                name="webdev"
                                type="checkbox"
                              />
                              <label>
                                {
                                  context.state.translation.default
                                    .projectrequest.webdev
                                }
                              </label>
                            </a>
                            <a className="checkButton">
                              <input
                                onChange={this.handleInputChange}
                                name="konzeption"
                                type="checkbox"
                              />
                              <label>
                                {
                                  context.state.translation.default
                                    .projectrequest.konzeption
                                }
                              </label>
                            </a>
                            <a className="checkButton">
                              <input
                                onChange={this.handleInputChange}
                                name="prototyping"
                                type="checkbox"
                              />
                              <label>Prototyping</label>
                            </a>
                            <a className="checkButton">
                              <input
                                onChange={this.handleInputChange}
                                name="mediaart"
                                type="checkbox"
                              />
                              <label>
                                {
                                  context.state.translation.default
                                    .projectrequest.mediaart
                                }
                              </label>
                            </a>
                            <a className="checkButton">
                              <input
                                onChange={this.handleInputChange}
                                name="sonstiges"
                                type="checkbox"
                              />
                              <label>
                                {
                                  context.state.translation.default
                                    .projectrequest.sonstiges
                                }
                              </label>
                            </a>
                          </div>
                          <p className="field__help-text error"></p>
                        </div>

                        <div className="field">
                          <input
                            required
                            name="name"
                            onChange={this.handleInputChange}
                            className="field__input"
                            type="text"
                            placeholder={
                              context.state.translation.default.projectrequest
                                .name
                            }
                          ></input>
                          <p className="field__help-text error"></p>
                        </div>
                        <div className="field">
                          <input
                            required
                            name="email"
                            onChange={this.handleInputChange}
                            className="field__input"
                            type="email"
                            placeholder={
                              context.state.translation.default.projectrequest
                                .email
                            }
                          ></input>
                          <p className="field__help-text error"></p>
                        </div>

                        <div className="field">
                          <textarea
                            required
                            name="text"
                            onChange={this.handleInputChange}
                            className="field__input"
                            type="text"
                            placeholder={
                              context.state.translation.default.projectrequest
                                .aboutProject
                            }
                          ></textarea>
                          <p className="field__help-text error"></p>
                        </div>
                        <button
                          className={
                            this.state.processStarted
                              ? "-sending btn-primary btn "
                              : "btn-primary btn "
                          }
                        >
                          <span class="btn-bg"></span>
                          <div class="lds-ellipsis">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                          </div>
                          <input
                            type="submit"
                            value={
                              context.state.translation.default.projectrequest
                                .button
                            }
                          />
                        </button>
                        <p className="p-tiny">
                          {" "}
                          <Link to={"/Datenschutz"}>
                            {" "}
                            {
                              context.state.translation.default.projectrequest
                                .datapolicy
                            }
                            .
                          </Link>
                        </p>
                        <div></div>
                      </form>

                      <div
                        className={
                          this.state.mailSent ? "success -active" : "success"
                        }
                      >
                        <div className="sucess-container">
                          <a onClick={this.closeSuccess} className="btn-close">
                            <svg
                              viewport="0 0 12 12"
                              version="1.1"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <line
                                x1="1"
                                y1="11"
                                x2="11"
                                y2="1"
                                stroke="black"
                                strokeWidth="2"
                              />
                              <line
                                x1="1"
                                y1="1"
                                x2="11"
                                y2="11"
                                stroke="black"
                                strokeWidth="2"
                              />
                            </svg>
                          </a>
                          <img src={illuDesign} />
                          <p className="p-big">
                            {" "}
                            {
                              context.state.translation.default.projectrequest
                                .thanks
                            }{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>{" "}
                </section>
              </Scene>
            </Controller>
          </Page>
        )}
      </MyContext.Consumer>
    );
  }
}
export default Projektanfrage;
