import React from "react";
import Page from "./Page";
import ProjectNav from "./ProjectNav";
import imgProjectHuman2 from "./assets/img/heyhuman.png";

import hyperHero from "./assets/img/hyperactive/hyper-hero.webm";
import hyperHeroMp4 from "./assets/img/hyperactive/hyper-hero.mp4";

import Hyphenated from "react-hyphen";
import de from "hyphenated-de";

import thumbnailHyperMobile from "./assets/img/hyperactive/hyperlazy.png";

import bookFront from "./assets/img/book-front.png";
import hyperPoster from "./assets/img/hyperactive/hyperPoster.jpg";
import ReactTwitchEmbedVideo from "react-twitch-embed-video";

import bookInhalt from "./assets/img/book-inhalt.png";
import bookInhalt2 from "./assets/img/book-inhalt2.png";
import hyperTeaserVideo from "./assets/img/hanskleinTeaser.webm";
import doku from "./assets/img/hyperactive/doku.webm";
import dokuMp4 from "./assets/img/hyperactive/doku.mp4";
import bookInlay from "./assets/img/book-inlay.png";

import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/opacity.css";

import hyperBook from "./assets/img/hyperactive/hyperBook.jpg";
import MediaQuery from "react-responsive";

import Slider from "react-slick";

import { motion } from "framer-motion";
import { Tween, Timeline } from "react-gsap";
import { Controller, Scene } from "react-scrollmagic";
import ReactPlayer from "react-player";
const transition = { duration: 0.4, ease: [0.6, 0.01, 0.4, 0.9] };
import { MyContext } from "./Context";

import { useElementProperties } from "react-element-size-and-position";
var imageY;

function vh(v) {
  var h = Math.max(
    document.documentElement.clientHeight,
    window.innerHeight || 0
  );
  return (v * h) / 100;
}

function vw(v) {
  var w = Math.max(
    document.documentElement.clientWidth,
    window.innerWidth || 0
  );
  return (v * w) / 100;
}

function vmin(v) {
  return Math.min(vh(v), vw(v));
}

function vmax(v) {
  return Math.max(vh(v), vw(v));
}

const TransitionGroup = (props) => {
  let heroHeight = useElementProperties("projectPageHero");
  if (heroHeight) {
    heroHeight = heroHeight.height;
    let heroVariants;
    if (props.props.state) {
      heroVariants = {
        initial: {
          width: props.props.state.width,
          borderRadius: "0.5em",
          height: props.props.state.height,
          y: -heroHeight + props.props.state.imageY,
          x: props.props.state.imageX,
        },
        animate: {
          width: "100%",
          borderRadius: "0",
          x: 0,
          y: 0,
          height: "100%",
          transition: { delay: 0.2, ...transition },
        },
      };
    }

    return (
      <motion.div
        style={{ overflow: "hidden" }}
        variants={heroVariants}
        className="fullscreenVideo "
      >
        <div className="video-wrap">
          <Controller>
            <Scene
              duration={600}
              triggerElement="#heroVid-wrap"
              triggerHook={0.3}
              classToggle="-active"
            >
              {(progress, event) => {
                if (progress > 0) {
                  var isPlaying = true;
                } else {
                  var isPlaying = false;
                }
                if (progress >= 1) {
                  var isPlaying = false;
                }
                return (
                  <figure className="lazy-load-image-background-wrap">
                    <ReactPlayer
                      height="100%"
                      style={{ display: "flex" }}
                      controls={false}
                      id="heroVid-wrap"
                      playing={isPlaying}
                      muted={true}
                      width="100%"
                      url={[hyperHero, hyperHeroMp4]}
                    />
                  </figure>
                );
              }}
            </Scene>
          </Controller>
        </div>
      </motion.div>
    );
  } else {
    return null;
  }
};

class Hyperactive extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      muted: true,
      seeking: false,
      played: 0,
    };
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <MyContext.Consumer>
        {(context) => (
          <Page
            pageName="Hyperaktivität"
            pageType="Project"
            loaderText="🎭 Hyperaktivität"
            pageHero="Hyperaktivität"
            pageStyle="Project"
          >
            <div className="freseaward">
              <motion.a
                initial={{ opacity: 0, y: "10%" }}
                animate={{ opacity: 1, y: "0%", transition: { delay: 0.5 } }}
                target="_blank"
                href="https://www.hfk-bremen.de/t/neuigkeiten-und-presse/n/frese-design-preis-gewinnerinnen-2020#cs_15"
              >
                🔥 Honorable Mention - Frese Design Award 2020 🥳
              </motion.a>
            </div>

            <div className="projectContent">
              <MediaQuery maxDeviceWidth={768}>
                <div className="lazy-load-image-background-wrap -displayMobile">
                  <LazyLoadImage effect="opacity" src={thumbnailHyperMobile} />
                </div>
              </MediaQuery>

              <MediaQuery minDeviceWidth={769}>
                <TransitionGroup props={this.props.location}></TransitionGroup>
              </MediaQuery>

              <section>
                <div className="wrap">
                  <div className="project-intro grid-spaceAround">
                    <div className=" col-28_sm-100 ">
                      <div className="grid-top grid-noGutter project-infos">
                        <div className="scope-wrap col-100_sm-50">
                          <h5>Scope</h5>
                          <p className="">UX/UI Design</p>
                          <p className="">Rapid Prototyping</p>
                          <p className="">Art Direction</p>
                          <p className="">3D Rendering</p>
                          <p className="">Creative Coding</p>
                        </div>
                      </div>
                    </div>
                    <div className=" col-65_sm-100">
                      <Hyphenated language={de}>
                        <p className="p-big">
                          {context.state.translation.default.hyperactivity.h1}
                        </p>
                      </Hyphenated>
                      <a
                        class="btn-primary btn"
                        target="_blank"
                        href="https://haenschenklein.wtf/"
                      >
                        Try it out!
                      </a>
                    </div>

                    <div className="col-100">
                      <div
                        className="fullscreenVideo"
                        style={{ paddingBottom: 0 }}
                      >
                        <div className="video-wrap">
                          <div
                            className={
                              this.state.muted
                                ? "soundControl"
                                : "soundControl -unMuted"
                            }
                            onClick={() =>
                              this.setState((prevState) => ({
                                muted: !prevState.muted,
                              }))
                            }
                          >
                            <svg viewBox="0 0 100 93">
                              <style>{"path{fill:#78849d}"}</style>
                              <path d="M58.1 14c-1-.6-2.3-.7-3.4-.1L21.9 30.3H8.3c-1.9 0-3.5 1.6-3.5 3.5v32.4c0 1.9 1.6 3.5 3.5 3.5h13.6l32.8 16.5c1.7.9 3.8.2 4.7-1.6.2-.5.4-1 .4-1.6V16.9c-.1-1.2-.7-2.3-1.7-2.9zM11.8 37.3h7.4v25.4h-7.4V37.3zm40.9 40.1L26.2 64V36l26.5-13.4v54.8zM83.8 22.5c-1.4-1.4-3.6-1.4-5 0s-1.4 3.6 0 5c12.5 12.5 12.5 32.7 0 45.1-1.4 1.4-1.4 3.6 0 5s3.6 1.4 5 0c15.2-15.2 15.2-39.8 0-55v-.1z" />
                              <path d="M71.5 34.7c-1.4-1.4-3.6-1.4-5 0s-1.4 3.6 0 5c5.7 5.7 5.7 14.9 0 20.6-1.4 1.4-1.4 3.6 0 5s3.6 1.4 5 0c8.5-8.4 8.5-22.1 0-30.6z" />
                            </svg>
                          </div>
                          <Controller>
                            <Scene
                              duration={600}
                              triggerElement="#mainVid-wrap"
                              triggerHook={0.3}
                              classToggle="-active"
                            >
                              {(progress, event) => {
                                if (progress > 0) {
                                  var isPlaying = true;
                                } else {
                                  var isPlaying = false;
                                }
                                if (progress >= 1) {
                                  var isPlaying = false;
                                }
                                return (
                                  <ReactPlayer
                                    controls={true}
                                    ref={this.ref}
                                    id="mainVid-wrap"
                                    playing={isPlaying}
                                    muted={this.state.muted}
                                    width="100%"
                                    height="100%"
                                    style={{ display: "flex" }}
                                    url={[doku, dokuMp4]}
                                  />
                                );
                              }}
                            </Scene>
                          </Controller>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {
                  // <div className="wrap grid">
                  //  <div style={{marginBottom: '10vmin'}}className=" col-100_sm-100">
                  // <h3>Einblicke in die Bedienung des Programms</h3>
                  //   </div>
                  //      <div className=" col-100_sm-100">
                  //      <ReactPlayer  controls={true}   ref={this.ref} width='100%' height='100%' url={doku}   />
                  // </div>
                  //
                  //
                  // </div>
                }
              </section>

              <section
                style={{
                  display: "flex",
                  flexDirection: "column",
                  paddingTop: 0,
                }}
              >
                <LazyLoadImage
                  offset={1400}
                  effect="opacity"
                  width={"100%"}
                  src={hyperBook}
                />
              </section>

              <section style={{ marginTop: "0", paddingTop: "0" }}>
                <figure
                  style={{ marginTop: "0", paddingTop: "0" }}
                  className="lazy-load-image-background-wrap"
                >
                  <ReactPlayer
                    height="80vh"
                    style={{ display: "flex" }}
                    controls={true}
                    playing={false}
                    width="100%"
                    url="https://vimeo.com/510795726"
                  />
                </figure>
              </section>
              <section style={{ marginTop: "0" }} className="project-kontakt">
                <div className="angebot -hansKlein">
                  <p className="p-big">
                    "The performative digital project unmasks and interacts with
                    the limitless configuration of how we connect and
                    communicate within this networked realm. It draws from a
                    semingly random and authorless perspective that reminds us
                    of fluxus or the surrealists. It suceeds in moving beyong a
                    dystopian design aesthetic to offer a more hopeful, playful
                    insight into the future of communication." ¹
                  </p>
                  <p style={{ marginTop: "2em" }}>
                    ¹ Laudatio
                    <a
                      className="inline-link"
                      target="_blank"
                      href="https://vimeo.com/441052417#t=23m30s"
                    >
                      {" "}
                      by Prof. Emily Smith
                    </a>
                  </p>
                </div>
              </section>
            </div>

            <ProjectNav context={context} pageName="Hyperactive"></ProjectNav>
          </Page>
        )}
      </MyContext.Consumer>
    );
  }
}
export default Hyperactive;
