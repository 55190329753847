import React from 'react';
import SadMeme from './assets/img/happy.jpg';
var canvasClass = "hidden";



class Canvas extends React.Component {
  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
    this.state = { width: 0, height: 0 };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }
  updateWindowDimensions() {
  this.setState({ width: window.innerWidth, height: window.innerHeight });
}
componentWillUnmount() {
  window.removeEventListener('resize', this.updateWindowDimensions);
}
  componentDidMount() {
    const canvas = this.canvasRef.current;
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);

  }

  componentDidUpdate() {
    // Draws a square in the middle of the canvas rotated
    // around the centre by this.props.angle


    const { randomX, randomY } = this.props;
    const canvas = this.canvasRef.current;
    const ctx = canvas.getContext('2d');
    const width = canvas.width;
    const height = canvas.height;
    canvas.width = this.state.width;
    canvas.height = this.state.height;
    ctx.save();
    ctx.beginPath();
    ctx.clearRect(randomX, randomY, width, height);
    ctx.translate(width / 2, height / 2);
    ctx.fillStyle = '#4397AC';
    ctx.fillRect(-width / 8, -height / 8, width / 4, height / 4);
    ctx.restore();
  }

  render() {
    return <canvas ref={this.canvasRef} />;
  }
}


export default Canvas
