import React from 'react';
import Page from './Page';
import Footer from './Footer';


export default function Impressum(props) {
  return (
    <Page pageName="Appdesign"
          pageHero="Appdesign"
          pageStyle=""
          pageType="Dienstleistung"
    >
  <section className="Impressum">
<div className="grid wrap -center">

      <div className="col-70_md-100 ">
      <h2>Headline</h2>
        <p>Toffee muffin bear claw ice cream gummies jujubes gingerbread. Apple pie bonbon pastry chocolate bar bear claw wafer bonbon. Marzipan topping wafer topping cake liquorice.</p>
        <p className="p-small">Wafer bonbon cupcake chocolate bar. Bear claw topping halvah chocolate bar gummi bears. Powder bonbon oat cake chocolate cupcake pudding pudding pastry cheesecake.</p>
      <p className="p-small">Toffee sweet soufflé danish. Lemon drops gummies fruitcake pudding tootsie roll pastry apple pie biscuit. Muffin gingerbread dessert marshmallow lemon drops chocolate bar jelly-o marzipan.</p>
  <h4>Headline</h4>
  <p>Toffee muffin bear claw ice cream gummies jujubes gingerbread. Apple pie bonbon pastry chocolate bar bear claw wafer bonbon. Marzipan topping wafer topping cake liquorice. Toffee muffin bear claw ice cream gummies jujubes gingerbread. Apple pie bonbon pastry chocolate bar bear claw wafer bonbon. Marzipan topping wafer topping cake liquorice.</p>

      </div>
    </div>

    </section>

    </Page>
  );
}
